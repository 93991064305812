<template>
	<v-app>
		<transition name="fade" mode="out-in">
			<router-view/>
		</transition>

		<app-snackbar/>
	</v-app>
</template>

<script>
	import AppSnackbar from "./components/Base/AppSnackbar";

	export default {
		name: 'App',
		components: {AppSnackbar},

		data: () => ({
			//
		}),
	};
</script>
