<template>
	<div>
		<div id="top-profile-page-info" class="my-10">
			<v-row>
				<v-col>
					<v-hover v-slot="{ hover }">
						<v-card
							class="mx-auto"
							height="250"
							max-height="300"
							:elevation="hover ? 10 : 2"
							:class="{ 'on-hover': hover }"
							outlined
						>
							<v-list-item>
								<v-icon
									size="130"
									:color="hover ? 'gray' : 'black'"
								>
									mdi-account-circle-outline
								</v-icon>

								<v-list-item-content>
									<v-card-text>
										<v-row>
											<v-col>

												<h2> Lorem ipsum project </h2> <br>
											</v-col>

										</v-row>

										<v-row>
											<v-col>
												<span> <b> Origination date: </b> 11/1/2021</span> <br>
												<span> <b> Target completion: </b> 11/1/2022</span> <br>
												<span> <b> Contract amount: </b> $1,250.00.00</span> <br>

												<span>
													1234 Happy Street
													Suite 1016
													Atlanta, GA 30332
												</span>
											</v-col>

											<v-col>
												<span> <b> Project manager: </b> <a> <u> Peter Projectmaker</u>  </a></span>
												<br>
												<span> <b> Customer contact: </b> <a>  <u> Chris Customer </u></a> </span>
												<br>
											</v-col>
											<v-spacer></v-spacer>

										</v-row>
									</v-card-text>
								</v-list-item-content>

							</v-list-item>

							<v-card-actions>
								<v-spacer></v-spacer>

								<div class="text-center">
									<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												v-bind="attrs"
												v-on="on"
											>
												Actions
												<v-icon
													right
													dark
													large
												>
													mdi-menu-down
												</v-icon>

											</v-btn>
										</template>
										<v-list>
											<v-list-item
												v-for="(item, index) in items"
												:key="index"
												@click.stop="openModal(index)"
												link
											>
												<v-list-item-title>{{ item.title }}</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</div>

							</v-card-actions>
						</v-card>

					</v-hover>
				</v-col>

			</v-row>

			<p v-if="isCertificateMissing" class="mt-3 pa-8 text-center" style="background-color: #FFBDA6">
				Missing Certification
			</p>
		</div>

		<div class="subcontractors-section my-7">
			<h2> Subcontractors</h2>

			<p v-if="subcontractors.length === 0"> You don't have any subcontractors yet. Click Actions > Add
				subcontractors to add one</p>

			<v-row>
				<v-col
					v-for="(subcontractor, index) in subcontractors"
					:key="index + '-insurance'"
					:cols="12"
					lg="4"
					md="12"
					sm="12"
					xs="12"
				>
					<v-hover v-slot="{ hover }">
						<v-card
							class="mx-auto"
							max-width="500"
							height="200"
							max-height="250"
							:elevation="hover ? 10 : 2"
							:class="{ 'on-hover': hover }"
							outlined
							:style="subcontractor.is_clear_to_work ? `` : `background-color: #FFBDA6`"

						>
							<v-list-item three-line>
								<v-list-item-content>
									<v-list-item-title class="text-h5 mb-1 font-weight-bold">
										{{ subcontractor.name }}
									</v-list-item-title>
									<v-spacer></v-spacer>

									<v-list-item-subtitle>

										<p>
											<span v-if="subcontractor.preferred_contractor" style="color: green"> Preferred </span>
											<span v-if="subcontractor.preferred_contractor !== true" style="color: red"> Not Preferred </span>
											<br>

											<span> {{ subcontractor.first_name }} {{
													subcontractor.last_name
												}}  {{ subcontractor.email }} </span> <br>
											<span> {{ subcontractor.phone }} </span> <br>
											<span> {{ subcontractor.trade }} </span> <br>

										</p>
									</v-list-item-subtitle>

								</v-list-item-content>
								<v-icon
									size="96"
									class="pt-5 mt-2"
									:color="hover ? 'gray' : 'black'"
								>
									mdi-lightning-bolt
								</v-icon>

							</v-list-item>
							<v-divider class="pa-0"></v-divider>
							<v-card-actions>
								<span v-if="subcontractor.is_clear_to_work" style="color: green"> Contractor is clear to work</span>
								<span v-if="subcontractor.is_clear_to_work == false" style="color: red"> Missing insurance</span>

								<v-spacer></v-spacer>

								<div class="text-center">
									<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">

											<v-btn
												v-bind="attrs"
												v-on="on"
												small
											>
												Actions
												<v-icon
													right
													dark
													large
												>
													mdi-menu-down
												</v-icon>

											</v-btn>
										</template>
										<v-list>
											<v-list-item
												v-for="(item, index) in cardActionItems"
												:key="index"
											>
												<v-list-item-title>{{ item.title }}</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</div>

							</v-card-actions>
						</v-card>
					</v-hover>
				</v-col>
			</v-row>
		</div>

		<div class="equipments-section my-7">
			<h2> Equipment</h2>

			<p v-if="equipments.length === 0"> You don't have any equipments yet. Click Actions > Add equipment to add
				one</p>

			<v-row>
				<v-col
					v-for="(equipment, index) in equipments"
					:key="index + '-insurance'"
					:cols="12"
					lg="4"
					md="12"
					sm="12"
					xs="12"
				>
					<v-hover v-slot="{ hover }">
						<v-card
							class="mx-auto"
							max-width="500"
							height="200"
							max-height="250"
							:elevation="hover ? 10 : 2"
							:class="{ 'on-hover': hover }"
							outlined

						>
							<v-list-item three-line>
								<v-list-item-content>
									<v-list-item-title class="text-h5 mb-1 font-weight-bold">
										{{ equipment.name }}
									</v-list-item-title>
									<v-spacer></v-spacer>

									<v-list-item-subtitle>

										<p>
											<span> <b>  Current responsible party: </b> </span> <br>
											<span> {{ equipment.responsible }} </span> <br>

											<span> <b> Current location: </b> </span> <br>
											<span> {{ equipment.location }} </span> <br>

										</p>
									</v-list-item-subtitle>
								</v-list-item-content>

								<v-img
									class="pt-5 mt-2"
									max-height="120"
									max-width="100"
									src="@/assets/img/tool.webp"
								></v-img>

							</v-list-item>
							<v-divider class="pa-0"></v-divider>
							<v-card-actions>
								<div v-if="equipment.tag">
									<v-icon>
										mdi-tag
									</v-icon>

									{{ equipment.tag }}
								</div>
								<v-spacer></v-spacer>

								<div class="text-center">
									<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">

											<v-btn
												v-bind="attrs"
												v-on="on"
											>
												Actions
												<v-icon
													right
													dark
													large
												>
													mdi-menu-down
												</v-icon>
											</v-btn>

										</template>
										<v-list>
											<v-list-item
												v-for="(item, index) in itemsActionsEquipmentCard"
												:key="index + '-profile'"
												@click.native="openActionModals(index, equipment)"
												link
											>
												<v-list-item-title>{{ item.title }}</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</div>

							</v-card-actions>
						</v-card>
					</v-hover>
				</v-col>
			</v-row>
		</div>

		<div class="subcontractors-section my-7">
			<h2> Certification </h2>

			<p v-if="certificates.length === 0"> You don't have any Certification yet. Click Actions > Add Certification
				to add one</p>
			<v-row>
				<v-col
					v-for="(certificate, index) in certificates"
					:key="index + '-certificate'"
					:cols="12"
					lg="4"
					md="12"
					sm="12"
					xs="12"
				>
					<v-hover v-slot="{ hover }">
						<v-card
							class="mx-auto"
							max-width="500"
							height="200"
							max-height="250"
							:elevation="hover ? 10 : 2"
							:class="{ 'on-hover': hover }"
							outlined

						>
							<v-list-item three-line>
								<v-list-item-content>
									<v-list-item-title class="text-h5 mb-1 font-weight-bold">
										{{ certificate.type }}
									</v-list-item-title>
									<v-spacer></v-spacer>

									<v-list-item-subtitle>

										<p>
											<span> {{ certificate.carrier }} </span> <br>
											<span> {{ certificate.valid_from_date }} </span> <br>
											<span> {{ certificate.expires_date }} </span> <br>
											<span> {{ certificate.notes }} </span> <br>
										</p>
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-divider class="pa-0"></v-divider>

							<v-card-actions>
								<v-btn
									text
									color="#1867C0"
									small
								>
									<u> Download file </u>
								</v-btn>

								<v-spacer></v-spacer>

								<div class="text-center">
									<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												v-bind="attrs"
												v-on="on"
												small
											>
												Actions
												<v-icon
													right
													dark
													large
												>
													mdi-menu-down
												</v-icon>

											</v-btn>
										</template>
										<v-list>
											<v-list-item
												v-for="(item, index) in cardActionItems"
												:key="index"
												link
											>
												<v-list-item-title>{{ item.title }}</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</div>

							</v-card-actions>
						</v-card>
					</v-hover>
				</v-col>
			</v-row>
		</div>

		<div class="equipments-section my-7">
			<h2> Insurance</h2>

			<p v-if="insurances.length === 0"> You don't have any insurances yet. Click Actions > Add insurances to add
				one</p>

			<v-row>
				<v-col
					v-for="(insurance, index) in insurances"
					:key="index + '-insurance'"
					:cols="12"
					lg="4"
					md="12"
					sm="12"
					xs="12"
				>
					<v-hover v-slot="{ hover }">
						<v-card
							class="mx-auto"
							max-width="500"
							height="200"
							max-height="250"
							:elevation="hover ? 10 : 2"
							:class="{ 'on-hover': hover }"
							outlined

						>
							<v-list-item three-line>
								<v-list-item-content>
									<v-list-item-title class="text-h5 mb-1 font-weight-bold">
										{{ insurance.type }}
									</v-list-item-title>
									<v-spacer></v-spacer>

									<v-list-item-subtitle>

										<p>
											<span> {{ insurance.carrier }} </span> <br>
											<span> {{ insurance.valid_from_date }} </span> <br>
											<span> {{ insurance.expires_date }} </span> <br>
											<span> {{ insurance.notes }} </span> <br>
										</p>
									</v-list-item-subtitle>
								</v-list-item-content>

							</v-list-item>
							<v-divider class="pa-0"></v-divider>
							<v-card-actions>
								<v-btn
									text
									color="#1867C0"
									small
								>
									<u> Download file </u>
								</v-btn>

								<v-spacer></v-spacer>

								<div class="text-center">
									<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												v-bind="attrs"
												v-on="on"
												small
											>
												Actions
												<v-icon
													right
													dark
													large
												>
													mdi-menu-down
												</v-icon>

											</v-btn>
										</template>
										<v-list>
											<v-list-item
												v-for="(item, index) in cardActionItems"
												:key="index"
												link
											>
												<v-list-item-title>{{ item.title }}</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</div>

							</v-card-actions>
						</v-card>
					</v-hover>
				</v-col>
			</v-row>

		</div>

		<SubcontractorAddInsuranceDialog
			v-model="showInsuranceDialog"
			:key="dialogKeyInsurance + '-insurance'"
			@save="saveInsurance"
		/>

		<SubcontractorAddCertificateDialog
			v-model="showCertificateDialog"
			:key="dialogKeyCertificate + '-certificate'"
			@save="saveCertificate"
		/>

		<ProjectAddSubcontractorDialog
			v-model="showAddSubcontractorDialog"
			:key="dialogKeySubcontractor + '-subcontractor'"
			@save="saveSubcontractor"
		/>

		<ProjectAddEquipmentDialog
			v-model="showAddEquipmentDialog"
			:key="dialogKeyEquipment + '-equipment'"
			@save="saveEquipment"
		/>

		<EquipmentAddTagDialog
			:key="equipmentTagKey+ '-tag'"
			v-model="equipmentTagDialog"
		/>

		<EquipmentDeleteDialog
			:edited-item-prop="editedItem"
			:key="equipmentDeleteKey + '-delete'"
			v-model="equipmentDeleteDialog"
			@deleteItemConfirm="deleteItemConfirm"
		/>

	</div>
</template>

<script>

	import OrganizationTable from "../components/Organization/OrganizationTable";
	import SubcontractorAddCertificateDialog from "../components/Subcontractor/SubcontractorAddCertificateDialog";
	import SubcontractorAddInsuranceDialog from "../components/Subcontractor/SubcontractorAddInsuranceDialog";
	import ProjectAddSubcontractorDialog from "../components/Project/ProjectAddSubcontractorDialog";
	import ProjectAddEquipmentDialog from "../components/Project/ProjectAddEquipmentDialog";
	import EquipmentAddTagDialog from "../components/Equipment/EquipmentAddTagDialog";
	import EquipmentDeleteDialog from "../components/Equipment/EquipmentDeleteDialog";

	export default {
		name: 'ProjectSinglePage',

		components: {
			EquipmentDeleteDialog,
			EquipmentAddTagDialog,
			ProjectAddEquipmentDialog,
			ProjectAddSubcontractorDialog,
			OrganizationTable,
			SubcontractorAddCertificateDialog,
			SubcontractorAddInsuranceDialog,
		},

		data: () => ({
			showAddEquipmentDialog: false,
			dialogKeyEquipment: 0,

			billingStatus: true,
			items: [
				{title: 'Add subcontractor'},
				{title: 'Add equipment'},
				{title: 'Add insurance requirement'},
				{title: 'Add certification requirement'},
			],
			itemsActionsEquipmentCard: [
				{title: 'Tag'},
				{title: 'Delete'},
			],

			cardActionItems: [
				{title: 'Edit'},
				{title: 'Delete'},
			],

			showInsuranceDialog: false,
			dialogKeyInsurance: 0,
			insurances: [
				{
					carrier: "Alice Smith",
					expires_date: "2021-12-15",
					file: "",
					notes: "General insurance",
					type: "General",
					valid_from_date: "2021-12-09",
				},

				{
					carrier: "Alice Smith",
					expires_date: "2021-12-15",
					file: "",
					notes: "General insurance",
					type: "General",
					valid_from_date: "2021-12-09",
				},
				{
					carrier: "Alice Smith",
					expires_date: "2021-12-15",
					file: "",
					notes: "General insurance",
					type: "General",
					valid_from_date: "2021-12-09",
				},

				{
					carrier: "Alice Smith",
					expires_date: "2021-12-15",
					file: "",
					notes: "General insurance",
					type: "General",
					valid_from_date: "2021-12-09",
				}, {
					carrier: "Alice Smith",
					expires_date: "2021-12-15",
					file: "",
					notes: "General insurance",
					type: "General",
					valid_from_date: "2021-12-09",
				},

				{
					carrier: "Alice Smith",
					expires_date: "2021-12-15",
					file: "",
					notes: "General insurance",
					type: "General",
					valid_from_date: "2021-12-09",
				},
				{
					carrier: "Alice Smith",
					expires_date: "2021-12-15",
					file: "",
					notes: "General insurance",
					type: "General",
					valid_from_date: "2021-12-09",
				},

				{
					carrier: "Alice Smith",
					expires_date: "2021-12-15",
					file: "",
					notes: "General insurance",
					type: "General",
					valid_from_date: "2021-12-09",
				}
			],

			showCertificateDialog: false,
			dialogKeyCertificate: 0,
			certificates: [
				{
					carrier: "John Doe",
					expires_date: "2021-12-15",
					file: "",
					notes: "Master plumber",
					type: "Certificate",
					valid_from_date: "2021-12-09",
				},
			],

			showAddSubcontractorDialog: false,
			dialogKeySubcontractor: 0,

			subcontractors: [],
			equipments: [],

			equipmentTagKey: 0,
			equipmentTagDialog: false,
			editedIndex: -1,
			editedItem: {},
			equipmentDeleteKey: 0,
			equipmentDeleteDialog: false,
		}),

		methods: {

			openModal(index) {

				if (index === 0) {

					this.showAddSubcontractorDialog = true;
					this.dialogKeySubcontractor++;
					return;
				}

				if (index === 1) {

					this.showAddEquipmentDialog = true;
					this.dialogKeyEquipment++;
					return;
				}

				if (index === 2) {

					this.showInsuranceDialog = true;
					this.dialogKeyInsurance++;
					return;
				}

				this.showCertificateDialog = true;
				this.dialogKeyCertificate++;
			},
			saveInsurance(itemInsurance) {

				this.insurances.push(itemInsurance);
			},

			saveCertificate(itemInsurance) {

				this.certificates.push(itemInsurance);
			},

			saveSubcontractor(item) {

				this.subcontractors.push(item);
			},

			saveEquipment(item) {

				this.equipments.push(item);
			},

			openAddTagDialog() {

				this.equipmentTagKey++;
				this.equipmentTagDialog = true;
			},

			openDeleteItemDialog(item) {

				this.editedIndex = this.equipments.indexOf(item);
				this.editedItem = Object.assign({}, item);
				this.equipmentDeleteKey++;
				this.equipmentDeleteDialog = true;
			},

			openActionModals(index, item) {

				if (index == 0) {

					this.openAddTagDialog();
					return
				}

				this.openDeleteItemDialog(item);
			},

			closeDelete() {

				this.editedIndex = -1;
				this.editedItem = {
					name: ''
				};
				this.equipmentDeleteDialog = false;
			},

			deleteItemConfirm() {

				this.equipments.splice(this.editedIndex, 1);
				this.closeDelete();
			},
		},

		computed: {

			isCertificateMissing() {

				return this.subcontractors.some((subcontractor) => {
					return subcontractor.is_clear_to_work == false
				});
			}
		}
	}
</script>

<style lang="scss" scoped>

	.v-list-item--three-line .v-list-item__subtitle {
		white-space: initial;
		-webkit-line-clamp: 4 !important;
	}

	.subcontractors-section {
		min-height: 200px;
	}

</style>