var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-10",attrs:{"id":"top-profile-page-info"}},[_c('v-row',[_c('v-col',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"height":"250","max-height":"300","elevation":hover ? 10 : 2,"outlined":""}},[_c('v-list-item',[_c('v-icon',{attrs:{"size":"130","color":hover ? 'gray' : 'black'}},[_vm._v(" mdi-account-circle-outline ")]),_c('v-list-item-content',[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('h2',[_vm._v(" Lorem ipsum project ")]),_vm._v(" "),_c('br')])],1),_c('v-row',[_c('v-col',[_c('span',[_c('b',[_vm._v(" Origination date: ")]),_vm._v(" 11/1/2021")]),_vm._v(" "),_c('br'),_c('span',[_c('b',[_vm._v(" Target completion: ")]),_vm._v(" 11/1/2022")]),_vm._v(" "),_c('br'),_c('span',[_c('b',[_vm._v(" Contract amount: ")]),_vm._v(" $1,250.00.00")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" 1234 Happy Street Suite 1016 Atlanta, GA 30332 ")])]),_c('v-col',[_c('span',[_c('b',[_vm._v(" Project manager: ")]),_vm._v(" "),_c('a',[_c('u',[_vm._v(" Peter Projectmaker")])])]),_c('br'),_c('span',[_c('b',[_vm._v(" Customer contact: ")]),_vm._v(" "),_c('a',[_c('u',[_vm._v(" Chris Customer ")])])]),_c('br')]),_c('v-spacer')],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" Actions "),_c('v-icon',{attrs:{"right":"","dark":"","large":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();return _vm.openModal(index)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)],1)],1)]}}])})],1)],1),(_vm.isCertificateMissing)?_c('p',{staticClass:"mt-3 pa-8 text-center",staticStyle:{"background-color":"#FFBDA6"}},[_vm._v(" Missing Certification ")]):_vm._e()],1),_c('div',{staticClass:"subcontractors-section my-7"},[_c('h2',[_vm._v(" Subcontractors")]),(_vm.subcontractors.length === 0)?_c('p',[_vm._v(" You don't have any subcontractors yet. Click Actions > Add subcontractors to add one")]):_vm._e(),_c('v-row',_vm._l((_vm.subcontractors),function(subcontractor,index){return _c('v-col',{key:index + '-insurance',attrs:{"cols":12,"lg":"4","md":"12","sm":"12","xs":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },style:(subcontractor.is_clear_to_work ? "" : "background-color: #FFBDA6"),attrs:{"max-width":"500","height":"200","max-height":"250","elevation":hover ? 10 : 2,"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5 mb-1 font-weight-bold"},[_vm._v(" "+_vm._s(subcontractor.name)+" ")]),_c('v-spacer'),_c('v-list-item-subtitle',[_c('p',[(subcontractor.preferred_contractor)?_c('span',{staticStyle:{"color":"green"}},[_vm._v(" Preferred ")]):_vm._e(),(subcontractor.preferred_contractor !== true)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" Not Preferred ")]):_vm._e(),_c('br'),_c('span',[_vm._v(" "+_vm._s(subcontractor.first_name)+" "+_vm._s(subcontractor.last_name)+" "+_vm._s(subcontractor.email)+" ")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" "+_vm._s(subcontractor.phone)+" ")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" "+_vm._s(subcontractor.trade)+" ")]),_vm._v(" "),_c('br')])])],1),_c('v-icon',{staticClass:"pt-5 mt-2",attrs:{"size":"96","color":hover ? 'gray' : 'black'}},[_vm._v(" mdi-lightning-bolt ")])],1),_c('v-divider',{staticClass:"pa-0"}),_c('v-card-actions',[(subcontractor.is_clear_to_work)?_c('span',{staticStyle:{"color":"green"}},[_vm._v(" Contractor is clear to work")]):_vm._e(),(subcontractor.is_clear_to_work == false)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" Missing insurance")]):_vm._e(),_c('v-spacer'),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_vm._v(" Actions "),_c('v-icon',{attrs:{"right":"","dark":"","large":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.cardActionItems),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)],1)],1)]}}],null,true)})],1)}),1)],1),_c('div',{staticClass:"equipments-section my-7"},[_c('h2',[_vm._v(" Equipment")]),(_vm.equipments.length === 0)?_c('p',[_vm._v(" You don't have any equipments yet. Click Actions > Add equipment to add one")]):_vm._e(),_c('v-row',_vm._l((_vm.equipments),function(equipment,index){return _c('v-col',{key:index + '-insurance',attrs:{"cols":12,"lg":"4","md":"12","sm":"12","xs":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"max-width":"500","height":"200","max-height":"250","elevation":hover ? 10 : 2,"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5 mb-1 font-weight-bold"},[_vm._v(" "+_vm._s(equipment.name)+" ")]),_c('v-spacer'),_c('v-list-item-subtitle',[_c('p',[_c('span',[_c('b',[_vm._v(" Current responsible party: ")])]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" "+_vm._s(equipment.responsible)+" ")]),_vm._v(" "),_c('br'),_c('span',[_c('b',[_vm._v(" Current location: ")])]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" "+_vm._s(equipment.location)+" ")]),_vm._v(" "),_c('br')])])],1),_c('v-img',{staticClass:"pt-5 mt-2",attrs:{"max-height":"120","max-width":"100","src":require("@/assets/img/tool.webp")}})],1),_c('v-divider',{staticClass:"pa-0"}),_c('v-card-actions',[(equipment.tag)?_c('div',[_c('v-icon',[_vm._v(" mdi-tag ")]),_vm._v(" "+_vm._s(equipment.tag)+" ")],1):_vm._e(),_c('v-spacer'),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" Actions "),_c('v-icon',{attrs:{"right":"","dark":"","large":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.itemsActionsEquipmentCard),function(item,index){return _c('v-list-item',{key:index + '-profile',attrs:{"link":""},nativeOn:{"click":function($event){return _vm.openActionModals(index, equipment)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)],1)],1)]}}],null,true)})],1)}),1)],1),_c('div',{staticClass:"subcontractors-section my-7"},[_c('h2',[_vm._v(" Certification ")]),(_vm.certificates.length === 0)?_c('p',[_vm._v(" You don't have any Certification yet. Click Actions > Add Certification to add one")]):_vm._e(),_c('v-row',_vm._l((_vm.certificates),function(certificate,index){return _c('v-col',{key:index + '-certificate',attrs:{"cols":12,"lg":"4","md":"12","sm":"12","xs":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"max-width":"500","height":"200","max-height":"250","elevation":hover ? 10 : 2,"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5 mb-1 font-weight-bold"},[_vm._v(" "+_vm._s(certificate.type)+" ")]),_c('v-spacer'),_c('v-list-item-subtitle',[_c('p',[_c('span',[_vm._v(" "+_vm._s(certificate.carrier)+" ")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" "+_vm._s(certificate.valid_from_date)+" ")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" "+_vm._s(certificate.expires_date)+" ")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" "+_vm._s(certificate.notes)+" ")]),_vm._v(" "),_c('br')])])],1)],1),_c('v-divider',{staticClass:"pa-0"}),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"#1867C0","small":""}},[_c('u',[_vm._v(" Download file ")])]),_c('v-spacer'),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_vm._v(" Actions "),_c('v-icon',{attrs:{"right":"","dark":"","large":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.cardActionItems),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)],1)],1)]}}],null,true)})],1)}),1)],1),_c('div',{staticClass:"equipments-section my-7"},[_c('h2',[_vm._v(" Insurance")]),(_vm.insurances.length === 0)?_c('p',[_vm._v(" You don't have any insurances yet. Click Actions > Add insurances to add one")]):_vm._e(),_c('v-row',_vm._l((_vm.insurances),function(insurance,index){return _c('v-col',{key:index + '-insurance',attrs:{"cols":12,"lg":"4","md":"12","sm":"12","xs":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"max-width":"500","height":"200","max-height":"250","elevation":hover ? 10 : 2,"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5 mb-1 font-weight-bold"},[_vm._v(" "+_vm._s(insurance.type)+" ")]),_c('v-spacer'),_c('v-list-item-subtitle',[_c('p',[_c('span',[_vm._v(" "+_vm._s(insurance.carrier)+" ")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" "+_vm._s(insurance.valid_from_date)+" ")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" "+_vm._s(insurance.expires_date)+" ")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" "+_vm._s(insurance.notes)+" ")]),_vm._v(" "),_c('br')])])],1)],1),_c('v-divider',{staticClass:"pa-0"}),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"#1867C0","small":""}},[_c('u',[_vm._v(" Download file ")])]),_c('v-spacer'),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_vm._v(" Actions "),_c('v-icon',{attrs:{"right":"","dark":"","large":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.cardActionItems),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)],1)],1)]}}],null,true)})],1)}),1)],1),_c('SubcontractorAddInsuranceDialog',{key:_vm.dialogKeyInsurance + '-insurance',on:{"save":_vm.saveInsurance},model:{value:(_vm.showInsuranceDialog),callback:function ($$v) {_vm.showInsuranceDialog=$$v},expression:"showInsuranceDialog"}}),_c('SubcontractorAddCertificateDialog',{key:_vm.dialogKeyCertificate + '-certificate',on:{"save":_vm.saveCertificate},model:{value:(_vm.showCertificateDialog),callback:function ($$v) {_vm.showCertificateDialog=$$v},expression:"showCertificateDialog"}}),_c('ProjectAddSubcontractorDialog',{key:_vm.dialogKeySubcontractor + '-subcontractor',on:{"save":_vm.saveSubcontractor},model:{value:(_vm.showAddSubcontractorDialog),callback:function ($$v) {_vm.showAddSubcontractorDialog=$$v},expression:"showAddSubcontractorDialog"}}),_c('ProjectAddEquipmentDialog',{key:_vm.dialogKeyEquipment + '-equipment',on:{"save":_vm.saveEquipment},model:{value:(_vm.showAddEquipmentDialog),callback:function ($$v) {_vm.showAddEquipmentDialog=$$v},expression:"showAddEquipmentDialog"}}),_c('EquipmentAddTagDialog',{key:_vm.equipmentTagKey+ '-tag',model:{value:(_vm.equipmentTagDialog),callback:function ($$v) {_vm.equipmentTagDialog=$$v},expression:"equipmentTagDialog"}}),_c('EquipmentDeleteDialog',{key:_vm.equipmentDeleteKey + '-delete',attrs:{"edited-item-prop":_vm.editedItem},on:{"deleteItemConfirm":_vm.deleteItemConfirm},model:{value:(_vm.equipmentDeleteDialog),callback:function ($$v) {_vm.equipmentDeleteDialog=$$v},expression:"equipmentDeleteDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }