var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.projects,"search":_vm.search,"sort-by":"primary_contact_email"},on:{"click:row":_vm.goToProject},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('span',{staticStyle:{"color":"green"}},[_vm._v(" Active ")]):_vm._e(),(item.active !== true)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" Inactive ")]):_vm._e()]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" $ "+_vm._s(item.totalAmount)+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('ProjectCreateEditDialog',{key:_vm.dialogKeyCreateEditDialog,attrs:{"edited-item-prop":_vm.editedItem,"edited-index-prop":_vm.editedIndex},on:{"update:editedItemProp":function($event){_vm.editedItem=$event},"update:edited-item-prop":function($event){_vm.editedItem=$event},"resetEditedItem":_vm.resetEditedItem,"save":_vm.save},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}),_c('ProjectDeleteDialog',{attrs:{"edited-item-prop":_vm.editedItem},on:{"update:editedItemProp":function($event){_vm.editedItem=$event},"update:edited-item-prop":function($event){_vm.editedItem=$event},"deleteItemConfirm":_vm.deleteItemConfirm},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"id":'edit-organization-button-' + index,"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"id":'delete-organization-button-' + index,"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDeleteItemDialog(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"id":"reload-data-button","color":"primary"},nativeOn:{"click":function($event){return _vm.initialize.apply(null, arguments)}}},[_vm._v(" RELOAD ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }