/** This middleware will take care of logged in users, who are trying go to login page.
 * If user already logged in, we redirect him directly on design dashboard.
 * If he logged out - we allow him to go to /login page.
 * */

export default function guest({to, next, store, nextMiddleware}) {

	let isAuthenticated = store.getters['auth/isAuthenticated']();

	if (isAuthenticated) {

		let path = '';

		return next({path: path});
	}

	return nextMiddleware();
}