var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.organizations,"sort-by":"email"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_vm._v(" Role "),_c('v-icon',{attrs:{"right":"","dark":"","dense":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.roles),function(role,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-list-item-title',[_vm._v(_vm._s(role.title))])],1)}),1)],1)],1)]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Foreign organization")]),_c('v-autocomplete',{staticClass:"mx-4 gray",attrs:{"loading":_vm.loading,"items":_vm.items,"search-input":_vm.search,"cache-items":"","dense":"","color":"white","hide-no-data":"","clearable":"","hide-details":"","solo-inverted":"","label":"Search for a foreign organization"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-icon',{attrs:{"id":'delete-organization-button-' + index,"small":""}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"my-4 "},[_vm._v(" No foreign organization memberships ")]),_c('v-btn',{staticClass:"my-4",attrs:{"small":""},on:{"click":_vm.initialize}},[_vm._v(" Reload ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }