<template>
	<div>
		<h1 class="my-3"> Subcontractors</h1>
		<SubcontractorTable/>
	</div>

</template>

<script>
	import SubcontractorTable from "../components/Subcontractor/SubcontractorTable";

	export default {
		name: 'Subcontractors',

		components: {
			SubcontractorTable
		},

	}
</script>
