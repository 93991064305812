<template>
	<v-dialog v-model="dialogDelete" max-width="800px">
		<v-card class="py-4">

			<v-card-title class="py-9">
				<h2> Manage Trades</h2>
			</v-card-title>

			<v-card-text>
				<v-row>
					<v-col
						cols="12"
						sm="12"
						md="4"
						class="py-0"
					>
						<v-checkbox
							dense
							v-model="editedItem.is_electrician"
							label="Electrician"
							outlined
							class="py-0"
						>
						</v-checkbox>

					</v-col>

					<v-col
						cols="12"
						sm="12"
						md="4"
						class="py-0"
					>
						<v-checkbox
							dense
							v-model="editedItem.is_hvac"
							label="HVAC"
							outlined
							class="py-0"
						>
						</v-checkbox>
					</v-col>

					<v-col
						cols="12"
						sm="12"
						md="4"
						class="py-0"
					>
						<v-checkbox
							dense
							v-model="editedItem.is_plumbing"
							label="Plumbing"
							outlined
							class="py-0"
						>
						</v-checkbox>
					</v-col>
				</v-row>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					id="cancel-button"
					color="red darken-1"
					text
					@click.native="closeDialog"
				>
					close
				</v-btn>

				<v-spacer></v-spacer>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		name: "SubcontractorManageTradesDialog",

		props: {

			value: {
				Boolean,
				default: false
			},

			editedItemProp: {
				type: Object,
				required: true,
				default: {
					is_plumbing: false,
					is_hvac: false,
					is_electrician: false
				}
			},
		},

		data() {
			return {
				editedItem: this.editedItemProp,
			}
		},

		methods: {

			save() {

				this.$emit('changeTrades', this.editedItem);
				this.closeDialog();
			},

			closeDialog() {

				this.dialogDelete = false;
			},
		},

		computed: {

			dialogDelete: {

				get() {
					return this.value
				},

				set(value) {
					this.$emit('input', value)
				}
			},

		},
	}
</script>

<style scoped>

</style>