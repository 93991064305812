<template>
	<div class="mt-2">
		<v-row>
			<v-col
				v-for="card in cards"
				:key="card.title"
				:cols="12"
				:lg="`${card.flex}`"
				md="12"
				sm="12"
				xs="12"
			>
				<v-hover v-slot="{ hover }">
					<v-card
						class="mx-auto"
						max-width="500"
						height="200"
						max-height="250"
						:elevation="hover ? 10 : 2"
						:class="{ 'on-hover': hover }"
						link
						outlined
						@click.native="$router.push(`${card.link}`)"
					>
						<v-list-item three-line>
							<v-list-item-content>
								<v-list-item-title class="text-h5 mb-1 font-weight-bold">
									{{ card.title }}
								</v-list-item-title>
								<v-spacer></v-spacer>

								<v-list-item-subtitle>
									{{ card.text }}
								</v-list-item-subtitle>
							</v-list-item-content>

							<v-icon
								size="96"
								class="pt-5 mt-2"
								:color="hover ? 'gray' : 'black'"
							>
								{{ card.icon }}
							</v-icon>
						</v-list-item>

						<v-card-actions>
							<v-btn
								text
								color="#1867C0"
								@click.stop="$router.push(`${card.link}`)"
							>
								<u> {{ card.buttonText }} </u>
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-hover>
			</v-col>
		</v-row>
	</div>
</template>

<script>

	export default {
		name: 'Home',
		components: {},

		data: () => ({
			cards: [
				{
					title: 'Home',
					text: 'Head over to your Home dashboard for an overview of your account and tasks.',
					buttonText: 'Go Home',
					link: '/',
					icon: 'mdi-home',
					flex: 4
				},
				{
					title: 'Billing',
					text: 'See billing information for customer organizations, manage subscriptions, payments, cards, and other billing actions.',
					buttonText: 'Go to billing dashboard',
					link: '/billing',
					icon: 'mdi-currency-usd',
					flex: 4
				},
				{
					title: 'Organizations',
					text: 'Manage customer organizations, user permissions, and other organizational tasks.',
					buttonText: 'Go to organizations dashboard',
					link: '/organizations',
					icon: 'mdi-tooltip-account',
					flex: 4
				},

				{
					title: 'Projects',
					text: 'Manage projects',
					buttonText: 'Go to projects dashboard',
					link: '/projects',
					icon: 'mdi-folder-open-outline',
					flex: 4
				},

				{
					title: 'Subcontractors',
					text: 'Manage subcontractors',
					buttonText: 'Go to subcontractors dashboard',
					link: '/subcontractors',
					icon: 'mdi-account-group',
					flex: 4
				},

				{
					title: 'Equipments',
					text: 'Manage equipments',
					buttonText: 'Go to equipments dashboard',
					link: '/equipment',
					icon: 'mdi-tools',
					flex: 4
				},

				{
					title: 'Scanners',
					text: 'Manage scanners',
					buttonText: 'Go to scanners dashboard',
					link: '/scanners',
					icon: 'mdi-tablet-android',
					flex: 4
				},

				{
					title: 'Tags',
					text: 'Manage tags',
					buttonText: 'Go to tags dashboard',
					link: '/tags',
					icon: 'mdi-tag-multiple',
					flex: 4
				},

				{
					title: 'Settings',
					text: 'Manage settings',
					buttonText: 'Go to settings dashboard',
					link: '/settings',
					icon: 'mdi-settings-helper',
					flex: 4
				},
			],
		}),
	}
</script>

<style lang="scss" scoped>

	.v-list-item--three-line .v-list-item__subtitle {
		white-space: initial;
		-webkit-line-clamp: 4 !important;
	}
</style>