<template>
	<div>

		<v-row class="d-flex justify-center align-center">
			<v-col
				cols="12"
				sm="12"
				md="10"
			>
				<h1 class="my-3"> Scanner</h1>
			</v-col>

			<v-col
				cols="12"
				sm="12"
				md="2"
			>
				<v-btn
					color="primary"
					dark
					class="mb-2 px-8"
					:color="'green'"
					@click.native="openNewScannerDialog"
				>
					New
				</v-btn>
			</v-col>

		</v-row>

		<v-row>
			<v-col
				v-for="(scanner, index) in scanners"
				:key="index + '-scanner'"
				:cols="12"
				lg="4"
				md="12"
				sm="12"
				xs="12"
			>
				<v-hover v-slot="{ hover }">
					<v-card
						class="mx-auto"
						max-width="500"
						height="180"
						max-height="300"
						:elevation="hover ? 10 : 2"
						:class="{ 'on-hover': hover }"
						outlined

					>
						<v-list-item>

							<v-icon
								class="pt-5 mt-2"
								size="64"
								:color="hover ? 'gray' : 'black'"
							>
								mdi-tablet-android
							</v-icon>

							<v-list-item-content class="d-flex justify-center align-center pl-3">
								<v-list-item-title class="text-h5 mb-1 font-weight-bold pt-8 ">
									{{ scanner.id }}
								</v-list-item-title>
								<v-spacer></v-spacer>

								<v-list-item-subtitle>

									<p>
										<b> Status: </b>
										<span
											v-if="scanner.assigned"> In use by {{ scanner.assigned_email }}  </span>
										<span v-if="scanner.assigned == false"> Unassigned  </span> <br>
									</p>
								</v-list-item-subtitle>
							</v-list-item-content>

							<!--							<v-icon-->
							<!--								size="92"-->
							<!--								class="pt-5 mt-2"-->
							<!--								:color="hover ? 'gray' : 'black'"-->
							<!--							>-->
							<!--								{{ scanner.icon }}-->
							<!--							</v-icon>-->

						</v-list-item>

						<v-divider class="pt-1"></v-divider>

						<v-card-actions class="py-1">

							<div v-if="scanner.tag">
								<v-icon>
									mdi-tag
								</v-icon>

								{{ scanner.tag }}
							</div>
							<v-spacer></v-spacer>

							<v-btn
								@click.native="scanner.assigned = !scanner.assigned"
							>
								<span v-if="scanner.assigned"> RELEASE</span>
								<span v-if="scanner.assigned == false"> USE THIS SCANNER</span>
							</v-btn>

						</v-card-actions>
					</v-card>
				</v-hover>
			</v-col>
		</v-row>

		<ScannerAddDialog
			:key="scannerKey"
			v-model="scannerDialog"
			@save="save"
		/>

	</div>
</template>

<script>

	import OrganizationTable from "../components/Organization/OrganizationTable";
	import ScannerAddDialog from "../components/Scanner/ScannerAddDialog";

	export default {
		name: 'Scanners',

		components: {
			ScannerAddDialog,
			OrganizationTable
		},

		data: () => ({

			scannerKey: 0,
			scannerDialog: false,

			scanners: [
				{
					id: '12345',
					assigned: true,
					assigned_email: 'admin@example.com',
				},
				{
					id: '12345',
					assigned: false,
					assigned_email: '',
				},
				{
					id: '12345',
					assigned: false,
					assigned_email: '',
				},
				{
					id: '12345',
					assigned: false,
					assigned_email: '',
				},
				{
					id: '12345',
					assigned: false,
					assigned_email: '',
				},
				{
					id: '12345',
					assigned: false,
					assigned_email: '',
				},
			],
			items: [
				{title: 'Tag'},
				{title: 'Delete'},
			],
		}),

		methods: {

			openNewScannerDialog() {

				this.scannerKey++;
				this.scannerDialog = true;
			},

			openAddTagDialog() {

				this.scannerTagKey++;
				this.scannerTagDialog = true;
			},

			openDeleteItemDialog() {

			},

			openActionModals(index) {

				if (index == 0) {

					this.openAddTagDialog();
					return
				}

				this.openDeleteItemDialog();
			},

			save(item) {

				this.scanners.push(item);
				this.$store.dispatch('snackbar/showSnackbarMessage', {
					message: 'Scanner added successfully',
					duration: 4000,
					mode: 'success'
				});
			}
		}
	}
</script>
