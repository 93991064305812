import $axios from '../plugins/axios'

class CardService {

	create(token) {

		return $axios.post('/cards', {stripe_token: token})
			.then(res => {

				return res.data;
			})
	}
}

export default new CardService();