<template>
	<v-dialog
		v-model="dialog"
		max-width="1100px"
		@click:outside="close"
	>

		<template v-slot:activator="{ on, attrs }">
			<v-btn
				color="primary"
				dark
				class="mb-2 px-8"
				:color="'green'"
				v-bind="attrs"
				v-on="on"
			>
				New
			</v-btn>
		</template>

		<v-card>
			<v-card-title class="d-flex justify-center">
				<span id="dialog-title" class="text-h5 text-center">{{ formTitle }}</span>
			</v-card-title>

			<v-card-text>
				<v-container>
					<v-row>
						<v-col
							cols="12"
							sm="12"
							md="12"
							class="py-0"
						>
							<v-text-field
								v-model="editedItem.name"
								dense
								label="Organization name"
								outlined
								required
								:error-messages="organizationNameError"
								@blur="$v.editedItem.name.$touch()"
							>
							</v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col
							cols="12"
							class="py-1"
						>
							<v-divider></v-divider>

							<h2> Organization’s primary contact </h2>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="4"
							class="py-0"
						>
							<v-text-field
								v-model="editedItem.primary_contact_first_name"
								dense
								required
								:error-messages="firstNameError"
								@blur="$v.editedItem.primary_contact_first_name.$touch()"
								label="First name"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="4"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.primary_contact_last_name"
								required
								:error-messages="lastNameError"
								@blur="$v.editedItem.primary_contact_last_name.$touch()"
								label="Last name"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="4"
							class="py-0"
						>

							<v-select
								v-model="editedItem.primary_contact_title_id"
								:items="titles"
								item-text="name"
								item-value="id"
								label="Title"
								required
								:error-messages="titleError"
								@blur="$v.editedItem.primary_contact_title_id.$touch()"
								dense
								outlined
							></v-select>

						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.primary_contact_phone_number"
								required
								:error-messages="phoneError"
								@blur="$v.editedItem.primary_contact_phone_number.$touch()"
								label="Phone number"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.primary_contact_email"
								required
								:error-messages="emailError"
								@blur="$v.editedItem.primary_contact_email.$touch()"
								label="Email address"
								outlined
							>
							</v-text-field>
						</v-col>
					</v-row>

					<v-row>

						<v-col
							cols="12"
							class="py-0"
						>
							<v-divider></v-divider>

							<h2> Billing </h2>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="3"
							class="py-0"
						>
							<v-switch
								v-model="editedItem.trial_period"
								flat
								:label="`Enable trial period`"
								class="mt-0"
							/>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="2"
							class="py-0"
						>
							<v-text-field
								v-model="editedItem.trial_period_days"
								required
								label="Trial period (Days)"
								:disabled="editedItem.trial_period === false"
								:error-messages="trialPeriodDaysErrors"
								@input="$v.editedItem.trial_period_days.$touch()"
								outlined
								dense
								type="number"
							/>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="2"
							class="py-0"
						>
							<v-switch
								v-model="editedItem.free_account"
								flat
								:label="`Free account`"
								class="mt-0"
							></v-switch>
						</v-col>
					</v-row>

					<v-row>

						<v-col
							cols="12"
							class="py-1"
						>
							<v-divider></v-divider>

							<h2> Payment </h2>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="12"
							class="py-3"
						>

							<div class="stripe-card" style="border: 1px solid #aaaaaa">
								<StripeElements
									:stripe-key="config.stripe_api_key"
									#default="{ elements }"
									ref="elms"
								>
									<StripeElement
										type="card"
										:elements="elements"
										:element-style='stripeOptions'
										:options="stripeOptions"
										ref="card"
									/>
								</StripeElements>
							</div>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.street"
								:disabled="editedItem.free_account"
								required
								:error-messages="streetError"
								@blur="$v.editedItem.street.$touch()"
								label="Street"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.suite"
								:disabled="editedItem.free_account"
								required
								:error-messages="suiteError"
								@blur="$v.editedItem.suite.$touch()"
								label="Suite"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="4"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.city"
								:disabled="editedItem.free_account"
								required
								:error-messages="cityError"
								@blur="$v.editedItem.city.$touch()"
								label="City"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="4"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.state"
								:disabled="editedItem.free_account"
								required
								:error-messages="stateError"
								@blur="$v.editedItem.state.$touch()"
								label="State"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="4"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.zip"
								:disabled="editedItem.free_account"
								required
								:error-messages="zipError"
								@blur="$v.editedItem.zip.$touch()"
								label="Zip"
								type="number"
								outlined
							>
							</v-text-field>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					id="cancel-button"
					color="red darken-1"
					style="color:white"
					@click.native="close"
					text
				>
					Cancel
				</v-btn>

				<v-btn
					id="create-edit-button"
					color="green darken-1"
					style="color:white"
					@click.native="save"
					:disabled="$v.$anyError"
					text
					:loading="buttonLoadingSpinner"
				>
					{{ buttonText }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import {validationMixin} from 'vuelidate'
	import {required, requiredIf, minValue, email} from 'vuelidate/lib/validators'
	import TitlesService from "@/services/titles.service";
	import CardService from "@/services/card.service"
	import OrganizationsService from "@/services/organizations.service"
	import $axios from "../../plugins/axios";
	import config from '@/config.json'
	import {StripeElements, StripeElement} from 'vue-stripe-elements-plus'
	// import numeric from "vuelidate/lib/validators/numeric";

	export default {
		name: "OrganizationCreateEditDialog",

		components: {
			StripeElements,
			StripeElement
		},

		props: {

			value: {
				Boolean,
				default: false
			},

			editedItemProp: {
				type: Object,
				required: true,
				default: {
					name: '',

					primary_contact_first_name: '',
					primary_contact_last_name: '',
					primary_contact_title_id: '',
					primary_contact_phone_number: '',
					primary_contact_email: '',

					trial_period: false,
					trial_period_days: null,
					free_account: false,
					isBillingRequire: false,

					stripe_token: {
						id: '',
						cardNumber: '',
						expiration: '',
						cvv: '',
					},
					street: '',
					suite: '',
					city: '',
					state: '',
					zip: '',
				}
			},

			editedIndexProp: {
				type: Number,
				required: true,
				default: false
			},

		},

		mixins: [validationMixin],

		validations: {

			editedItem: {
				name: {required},

				primary_contact_first_name: {required},
				primary_contact_last_name: {required},
				primary_contact_title_id: {required},
				primary_contact_phone_number: {required},
				primary_contact_email: {required, email},

				trial_period_days: {
					required: requiredIf(function () {
						return this.editedItem.trial_period
					}), minValue: minValue(1)
				},

				street: {
					required: requiredIf(function () {
						return !this.editedItem.free_account
					})
				},
				suite: {
					required: requiredIf(function () {
						return !this.editedItem.free_account
					})
				},
				city: {
					required: requiredIf(function () {
						return !this.editedItem.free_account
					})
				},
				state: {
					required: requiredIf(function () {
						return !this.editedItem.free_account
					})
				},
				zip: {
					required: requiredIf(function () {
						return !this.editedItem.free_account
					}), minValue: minValue(1)
				},

			}
		},

		data() {
			return {
				complete: false,
				config,

				stripeOptions: {
					hidePostalCode: true,
					style: {
						base: {
							color: '#32325d',
							lineHeight: '40px',
							fontFamily: '"Raleway", Helvetica, sans-serif',
							fontSmoothing: 'antialiased',
							fontSize: '16px',
							'::placeholder': {
								color: '#aab7c4'
							}
						},
						invalid: {
							color: '#fa755a',
							iconColor: '#fa755a'
						}
					}
				},

				cardOptions: {
					// https://stripe.com/docs/stripe.js#element-options
				},

				editedItem: this.editedItemProp,
				editedIndex: this.editedIndexProp,
				titles: [],

				defaultItem: {
					name: '',
					primary_contact_first_name: '',
					primary_contact_last_name: '',
					primary_contact_title_id: '',
					primary_contact_phone_number: '',
					primary_contact_email: '',

					trial_period: false,
					trial_period_days: null,
					free_account: false,

					street: '',
					suite: '',
					city: '',
					state: '',
					zip: '',

					stripe_token: {
						id: '',
						cardNumber: '',
						expiration: '',
						cvv: '',
					},

				},
				buttonLoadingSpinner: false,
			}
		},

		methods: {

			async getAllTitles() {

				this.titles = await TitlesService.getTitles();
			},

			close() {

				this.dialog = false;
				this.$emit('resetEditedItem', this.defaultItem);
				this.editedIndex = -1;
				this.editedItem = Object.assign({}, this.defaultItem);
				this.$v.$reset();
			},

			prepareFormDataForOrganization(card_id) {

				let bodyFormData = new FormData();

				bodyFormData.set('name', this.editedItem.name);
				bodyFormData.set('street', this.editedItem.street);
				bodyFormData.set('suite', this.editedItem.suite);
				bodyFormData.set('city', this.editedItem.city);
				bodyFormData.set('state', this.editedItem.state);
				bodyFormData.set('zip', this.editedItem.zip);
				bodyFormData.set('primary_contact_first_name', this.editedItem.primary_contact_first_name);
				bodyFormData.set('primary_contact_last_name', this.editedItem.primary_contact_last_name);
				bodyFormData.set('primary_contact_title_id', this.editedItem.primary_contact_title_id);
				bodyFormData.set('primary_contact_phone_number', this.editedItem.primary_contact_phone_number);
				bodyFormData.set('primary_contact_email', this.editedItem.primary_contact_email);
				bodyFormData.set('trial_period', this.editedItem.trial_period);
				bodyFormData.set('free_account', this.editedItem.free_account);
				bodyFormData.set('stripe_token_id', card_id);

				if (this.editedItem.trial_period === true) {
					bodyFormData.set('trial_period_days', this.editedItem.trial_period_days);
				}

				return bodyFormData;
			},

			async editOrganizationData(token_id = null) {

				let formData = await this.prepareFormDataForOrganization(token_id);

				return OrganizationsService.editOrganization(formData, this.editedItemProp.id)
					.then((res) => {
						return res;
					});
			},

			async submitOrganizationData(token_id = null) {

				let formData = await this.prepareFormDataForOrganization(token_id);

				return OrganizationsService.createOrganization(formData)
					.then((res) => {
						return res;
					});
			},

			async addNewOrganization() {

				return this.stripeSubmit()
					.then((data) => {
						this.submitOrganizationData(data.token.id);
					});
			},


			async editNewOrganization() {

				return
				// this.stripeSubmit()
				// 	.then((data) => {
				// 		this.editOrganizationData(data.token.id);
				// 	});
			},

			stripeSubmit() {

				const groupComponent = this.$refs.elms
				const cardComponent = this.$refs.card
				// Get stripe element
				const cardElement = cardComponent.stripeElement;

				return groupComponent.instance.createToken(cardElement)
					.then(data => {
						return data
					});
			},

			save() {

				this.buttonLoadingSpinner = true;
				this.$v.$touch();

				if (this.$v.$anyError) {

					this.$store.dispatch('snackbar/showSnackbarMessage', {
						message: 'Please enter valid data',
						duration: 6000,
						mode: 'fail'
					});

					this.buttonLoadingSpinner = false;
					return;
				}

				if (this.editedIndex > -1) {


					this.editNewOrganization()
						.then(() => {

							this.$emit('refreshOrganizations');
							this.$store.dispatch('snackbar/showSnackbarMessage', {
								message: 'Organization was edited successfully',
								duration: 4000,
								mode: 'success'
							});

							this.buttonLoadingSpinner = false;
							this.close();
						});

					return;
				}

				this.addNewOrganization()
					.then(() => {

						this.$emit('refreshOrganizations');
						this.$store.dispatch('snackbar/showSnackbarMessage', {
							message: 'Organization was added successfully',
							duration: 4000,
							mode: 'success'
						});

						this.buttonLoadingSpinner = false;
						this.close();
					});

				// this.dialog = false;
			},
		},

		computed: {

			formTitle() {
				return this.editedIndex === -1 ? 'Create new organization' : 'Edit organization';
			},

			buttonText() {
				return this.editedIndex === -1 ? 'CREATE ORGANIZATION' : 'EDIT ORGANIZATION';
			},

			dialog: {

				get() {
					return this.value
				},

				set(value) {
					this.$emit('input', value)
				}
			},

			organizationNameError() {

				const errors = []
				if (!this.$v.editedItem.name.$dirty) {
					return errors
				}
				!this.$v.editedItem.name.required && errors.push('Organization name is required.')
				return errors
			},

			firstNameError() {

				const errors = []
				if (!this.$v.editedItem.primary_contact_first_name.$dirty) {
					return errors
				}
				!this.$v.editedItem.primary_contact_first_name.required && errors.push('First name is required.')
				return errors
			},

			lastNameError() {

				const errors = []
				if (!this.$v.editedItem.primary_contact_last_name.$dirty) {
					return errors
				}
				!this.$v.editedItem.primary_contact_last_name.required && errors.push('Last name is required.')
				return errors
			},

			titleError() {

				const errors = []
				if (!this.$v.editedItem.primary_contact_title_id.$dirty) {
					return errors
				}
				!this.$v.editedItem.primary_contact_title_id.required && errors.push('Title is required.')
				return errors
			},

			phoneError() {

				const errors = []
				if (!this.$v.editedItem.primary_contact_phone_number.$dirty) {
					return errors
				}
				!this.$v.editedItem.primary_contact_phone_number.required && errors.push('Phone is required.')
				return errors
			},

			emailError() {

				const errors = []
				if (!this.$v.editedItem.primary_contact_email.$dirty) {
					return errors
				}
				!this.$v.editedItem.primary_contact_email.email && errors.push('Must be valid e-mail')
				!this.$v.editedItem.primary_contact_email.required && errors.push('Email is required.')
				return errors
			},

			trialPeriodDaysErrors() {

				const errors = []
				if (!this.$v.editedItem.trial_period_days.$dirty) {
					return errors
				}
				!this.$v.editedItem.trial_period_days.minValue && errors.push('Enter positive number for days')
				!this.$v.editedItem.trial_period_days.required && errors.push('Trial days are required.')
				return errors
			},

			streetError() {

				const errors = []
				if (!this.$v.editedItem.street.$dirty) {
					return errors
				}
				!this.$v.editedItem.street.required && errors.push('Street is required.')
				return errors
			},

			suiteError() {

				const errors = []
				if (!this.$v.editedItem.suite.$dirty) {
					return errors
				}
				!this.$v.editedItem.suite.required && errors.push('Suite is required.')
				return errors
			},

			cityError() {

				const errors = []
				if (!this.$v.editedItem.city.$dirty) {
					return errors
				}
				!this.$v.editedItem.city.required && errors.push('City is required.')
				return errors
			},

			stateError() {

				const errors = []
				if (!this.$v.editedItem.state.$dirty) {
					return errors
				}
				!this.$v.editedItem.state.required && errors.push('State is required.')
				return errors
			},

			zipError() {

				const errors = []
				if (!this.$v.editedItem.zip.$dirty) {
					return errors
				}
				!this.$v.editedItem.zip.required && errors.push('Zip code is required.')
				!this.$v.editedItem.trial_period_days.minValue && errors.push('Enter correct zip')
				return errors
			},

		},

		created() {

			this.getAllTitles();
		}
	}
</script>

<style lang="scss" scoped>

	.v-label {
		font-size: 14px !important;
	}

	.stripe-card {
		width: 100%;
		height: 45px;
		border-radius: 4px;
	}
</style>