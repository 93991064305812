import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import AppContent from "../AppContent";

Vue.use(VueRouter)

import guest from './middleware/guest'
import auth from './middleware/auth'

import Billing from "../views/Billing";
import Organizations from "../views/Organizations";
import AuthLogin from "../views/Auth/AuthLogin";
import AuthRegister from "../views/Auth/AuthRegister";
import OrganizationProfile from "../views/OrganizationProfile";
import UserProfile from "../views/UserProfile";
import store from "../store"
import middlewarePipeline from "./middlewarePipeline";
import Projects from "../views/Projects";
import Subcontractors from "../views/Subcontractors";
import Equipment from "../views/Equipment";
import ProjectSinglePage from "../views/ProjectSinglePage";
import SubcontractorSinglePage from "../views/SubcontractorSinglePage";
import Scanners from "../views/Scanners";
import Tags from "../views/Tags";
import Settings from "../views/Settings";

const routes = [
	{
		path: '/',
		component: AppContent,
		children: [
			{
				path: '',
				redirect: '/dashboard',
				meta: {
					middleware: [auth]
				}
			},

			{
				path: '/dashboard',
				component: Home,
				meta: {
					middleware: [auth]
				}
			},

			{
				path: '/billing',
				component: Billing,
				meta: {
					middleware: [auth]
				}
			},

			{
				path: '/organizations',
				component: Organizations,
				meta: {
					middleware: [auth]
				}
			},

			{
				path: '/organizations/:id',
				component: OrganizationProfile,
				meta: {
					middleware: [auth]
				}
			},

			{
				path: '/projects',
				component: Projects,
				meta: {
					middleware: [auth]
				}
			},

			{
				path: '/projects',
				component: Projects,
				meta: {
					middleware: [auth]
				}
			},

			{
				path: '/projects/:id',
				component: ProjectSinglePage,
				meta: {
					middleware: [auth]
				}
			},

			{
				path: '/subcontractors',
				component: Subcontractors,
				meta: {
					middleware: [auth]
				}
			},

			{
				path: '/subcontractors/:id',
				component: SubcontractorSinglePage,
				meta: {
					middleware: [auth]
				}
			},

			{
				path: '/equipment',
				component: Equipment,
				meta: {
					middleware: [auth]
				}
			},

			{
				path: '/scanners',
				component: Scanners,
				meta: {
					middleware: [auth]
				}
			},

			{
				path: '/tags',
				component: Tags,
				meta: {
					middleware: [auth]
				}
			},

			{
				path: '/settings',
				component: Settings,
				meta: {
					middleware: [auth]
				}
			},

			{
				path: '/profile',
				component: UserProfile,
				meta: {
					middleware: [auth]
				}
			},

		],
	},

	{
		path: '/login',
		component: AuthLogin,
		meta: {
			middleware: [guest]
		}
	},

	{
		path: '/register',
		component: AuthRegister,
		meta: {
			middleware: [guest]
		}
	},

	// TO DO
	// {path: '/404', component: PageNotFound},
	{path: '*', redirect: '/404'},
]

const router = new VueRouter({
	mode: 'history',
	// base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {

	if (!to.meta.middleware) {
		return next()
	}

	const middleware = to.meta.middleware;

	const context = {
		to,
		from,
		next,
		store
	};

	return middleware[0]({
		...context,
		nextMiddleware: middlewarePipeline(context, middleware, 1)
	});
});

export default router
