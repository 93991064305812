var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-10",attrs:{"id":"top-profile-page-info"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"height":"200","max-height":"250","elevation":hover ? 10 : 2,"outlined":""}},[_c('v-list-item',[_c('v-icon',{attrs:{"size":"130","color":hover ? 'gray' : 'black'}},[_vm._v(" mdi-account-circle-outline ")]),_c('v-list-item-content',[_c('v-list-item-title',[_c('h4',[_vm._v(" John Smith ")]),_vm._v(" "),_c('br')]),_c('v-list-item-subtitle',[_c('a',{attrs:{"href":"mailto:john.b.smith@org.org"}},[_vm._v(" john.b.smith@org.org ")])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" Actions "),_c('v-icon',{attrs:{"right":"","dark":"","large":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index + '-profile',attrs:{"link":""},nativeOn:{"click":function($event){return _vm.openAddCardModal.apply(null, arguments)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)],1)],1)]}}])})],1)],1)],1),_c('div',{staticClass:"cards my-9"},[_c('v-row',_vm._l((_vm.cards),function(card,index){return _c('v-col',{key:index + '-card',attrs:{"cols":12,"lg":"4","md":"12","sm":"12","xs":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"max-width":"500","height":"200","max-height":"250","elevation":hover ? 10 : 2,"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5 mb-1 font-weight-bold"},[_vm._v(" Payment Methods ")]),_c('v-spacer'),_c('v-list-item-subtitle',[_c('p',[_c('span',[_vm._v(" "+_vm._s(card.name)+" ")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" 4*** **** **** 1111 ")]),_vm._v(" "),_c('br')])])],1),_c('v-icon',{staticClass:"pt-5 mt-2",attrs:{"size":"120","color":hover ? 'gray' : 'black'}},[_vm._v(" mdi-currency-usd ")])],1),_c('v-divider',{staticClass:"pa-0"}),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"#1867C0","small":""}},[_c('u',[_vm._v(" Manage payment method")])]),_c('v-spacer'),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_vm._v(" Actions "),_c('v-icon',{attrs:{"right":"","dark":"","large":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.cardActionItems),function(item,indexAction){return _c('v-list-item',{key:index + indexAction +'-card',attrs:{"link":""},nativeOn:{"click":function($event){return _vm.cardAction(indexAction, card)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)],1)],1)]}}],null,true)})],1)}),1)],1),_c('OrganizationTableForeignMemberships'),_c('ProfileAddCardDialog',{key:_vm.cardModalKey + '-card',attrs:{"card-item-prop":_vm.editedItem,"card-item-index":_vm.editedIndex},on:{"save":_vm.saveCard,"resetEditedItem":_vm.resetEditedItem},model:{value:(_vm.cardModalDialog),callback:function ($$v) {_vm.cardModalDialog=$$v},expression:"cardModalDialog"}}),_c('ProfileCardDeleteDialog',{key:_vm.cardModalDeleteKey + '-delete-card',attrs:{"edited-item-prop":_vm.editedItem},on:{"update:editedItemProp":function($event){_vm.editedItem=$event},"update:edited-item-prop":function($event){_vm.editedItem=$event},"deleteItemConfirm":_vm.deleteItemConfirm},model:{value:(_vm.cardModalDeleteDialog),callback:function ($$v) {_vm.cardModalDeleteDialog=$$v},expression:"cardModalDeleteDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }