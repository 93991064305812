<template>
	<div>
		<h1>Settings</h1>
	</div>
</template>

<script>

	export default {
		name: 'Settings',

		components: {},
	}
</script>
