<template>
	<div>
		<div id="top-profile-page-info" class="my-10">
			<v-row>
				<v-col>

					<v-hover v-slot="{ hover }">
						<v-card
							class="mx-auto"

							height="220"
							max-height="270"
							:elevation="hover ? 10 : 2"
							:class="{ 'on-hover': hover }"
							outlined
						>
							<v-list-item>

								<v-icon
									size="130"
									:color="hover ? 'gray' : 'black'"
								>
									mdi-account-circle-outline
								</v-icon>

								<v-list-item-content>
									<v-row>
										<v-col>

											<h2> Lorem ipsum project </h2> <br>

											<p><b> Primary Contact: </b> John Smith <a> email</a></p>

											<span> <b>  Contact address: </b>  </span> <br>
											<span>
												1234 Happy Street
												Suite 1016
												Atlanta, GA 30332
												404-606-433
											</span>
										</v-col>

										<v-col class="d-flex align-end ">
											<v-spacer></v-spacer>
											<div><b> Service Radius: </b>
												<span
													style="width: 40px; height: 40px; border: 1px solid; padding: 10px; margin-left: 10px;"
												>
													350
												</span>
											</div>
										</v-col>
										<v-spacer></v-spacer>
									</v-row>
								</v-list-item-content>

							</v-list-item>

							<v-card-actions>
								<v-spacer></v-spacer>

								<div class="text-center">
									<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												v-bind="attrs"
												v-on="on"
											>
												Actions
												<v-icon
													right
													dark
													large
												>
													mdi-menu-down
												</v-icon>

											</v-btn>
										</template>
										<v-list>
											<v-list-item
												v-for="(item, index) in items"
												:key="index"
												@click.stop="openModal(index)"
												link
											>
												<v-list-item-title>{{ item.title }}</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</div>

							</v-card-actions>
						</v-card>
					</v-hover>
				</v-col>

			</v-row>
		</div>

		<div class="subcontractors-section my-7">
			<h2> Trades </h2>

			<p v-if="isAnyTrades == false"> You don't have any subcontractors yet. Click Actions > Add subcontractors to
				add one</p>

			<v-row justify="space-around">
				<v-col
					cols="12"
					sm="12"
					md="12"
				>
					<v-sheet
						elevation="10"
						class="py-4 px-1"
					>
						<v-chip-group
							:disabled="false"
							active-class="primary--text"
						>
							<v-chip v-if="tradesOptions.is_electrician">
								Electrician
							</v-chip>

							<v-chip v-if="tradesOptions.is_hvac">
								HVAC
							</v-chip>

							<v-chip v-if="tradesOptions.is_plumbing">
								Plumbing
							</v-chip>
						</v-chip-group>
					</v-sheet>
				</v-col>
			</v-row>
		</div>

		<div class="subcontractors-section my-7">
			<h2> Certification </h2>

			<p v-if="certificates.length === 0"> You don't have any Certification yet. Click Actions > Add Certification
				to add one</p>
			<v-row>
				<v-col
					v-for="(certificate, index) in certificates"
					:key="index + '-certificate'"
					:cols="12"
					lg="4"
					md="12"
					sm="12"
					xs="12"
				>
					<v-hover v-slot="{ hover }">
						<v-card
							class="mx-auto"
							max-width="500"
							height="200"
							max-height="250"
							:elevation="hover ? 10 : 2"
							:class="{ 'on-hover': hover }"
							outlined

						>
							<v-list-item three-line>
								<v-list-item-content>
									<v-list-item-title class="text-h5 mb-1 font-weight-bold">
										{{ certificate.type }}
									</v-list-item-title>
									<v-spacer></v-spacer>

									<v-list-item-subtitle>

										<p>
											<span> {{ certificate.carrier }} </span> <br>
											<span> {{ certificate.valid_from_date }} </span> <br>
											<span> {{ certificate.expires_date }} </span> <br>
											<span> {{ certificate.notes }} </span> <br>
										</p>
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-divider class="pa-0"></v-divider>

							<v-card-actions>
								<v-btn
									text
									color="#1867C0"
									small
								>
									<u> Download file </u>
								</v-btn>

								<v-spacer></v-spacer>

								<div class="text-center">
									<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												v-bind="attrs"
												v-on="on"
												small
											>
												Actions
												<v-icon
													right
													dark
													large
												>
													mdi-menu-down
												</v-icon>

											</v-btn>
										</template>
										<v-list>
											<v-list-item
												v-for="(item, index) in cardActionItems"
												:key="index"
												link
											>
												<v-list-item-title>{{ item.title }}</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</div>

							</v-card-actions>
						</v-card>
					</v-hover>
				</v-col>
			</v-row>
		</div>

		<div class="equipments-section my-7">
			<h2> Insurance</h2>

			<p v-if="insurances.length === 0"> You don't have any insurances yet. Click Actions > Add insurances to add
				one</p>

			<v-row>
				<v-col
					v-for="(insurance, index) in insurances"
					:key="index + '-insurance'"
					:cols="12"
					lg="4"
					md="12"
					sm="12"
					xs="12"
				>
					<v-hover v-slot="{ hover }">
						<v-card
							class="mx-auto"
							max-width="500"
							height="200"
							max-height="250"
							:elevation="hover ? 10 : 2"
							:class="{ 'on-hover': hover }"
							outlined

						>
							<v-list-item three-line>
								<v-list-item-content>
									<v-list-item-title class="text-h5 mb-1 font-weight-bold">
										{{ insurance.type }}
									</v-list-item-title>
									<v-spacer></v-spacer>

									<v-list-item-subtitle>

										<p>
											<span> {{ insurance.carrier }} </span> <br>
											<span> {{ insurance.valid_from_date }} </span> <br>
											<span> {{ insurance.expires_date }} </span> <br>
											<span> {{ insurance.notes }} </span> <br>
										</p>
									</v-list-item-subtitle>
								</v-list-item-content>

							</v-list-item>
							<v-divider class="pa-0"></v-divider>
							<v-card-actions>
								<v-btn
									text
									color="#1867C0"
									small
								>
									<u> Download file </u>
								</v-btn>

								<v-spacer></v-spacer>

								<div class="text-center">
									<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												v-bind="attrs"
												v-on="on"
												small
											>
												Actions
												<v-icon
													right
													dark
													large
												>
													mdi-menu-down
												</v-icon>

											</v-btn>
										</template>
										<v-list>
											<v-list-item
												v-for="(item, index) in cardActionItems"
												:key="index"
												link
											>
												<v-list-item-title>{{ item.title }}</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</div>

							</v-card-actions>
						</v-card>
					</v-hover>
				</v-col>
			</v-row>

		</div>

		<SubcontractorManageTradesDialog
			v-model="showManageDialog"
			:key="dialogKeyTrades + '-trades'"
			:edited-item-prop.sync="tradesOptions"
			@changeTrades="changeTrades"
		/>

		<SubcontractorAddInsuranceDialog
			v-model="showInsuranceDialog"
			:key="dialogKeyInsurance + '-insurance'"
			@save="saveInsurance"
		/>

		<SubcontractorAddCertificateDialog
			v-model="showCertificateDialog"
			:key="dialogKeyCertificate + '-certificate'"
			@save="saveCertificate"
		/>

	</div>
</template>

<script>

	import SubcontractorManageTradesDialog from "../components/Subcontractor/SubcontractorManageTradesDialog";
	import SubcontractorAddInsuranceDialog from "../components/Subcontractor/SubcontractorAddInsuranceDialog";
	import SubcontractorAddCertificateDialog from "../components/Subcontractor/SubcontractorAddCertificateDialog";

	export default {
		name: 'SubcontractorSinglePage',

		components: {
			SubcontractorAddCertificateDialog,
			SubcontractorAddInsuranceDialog,
			SubcontractorManageTradesDialog
		},

		data: () => ({

			billingStatus: true,
			items: [
				{title: 'Manage trades'},
				{title: 'Add insurance'},
				{title: 'Add certification'},
			],
			cardActionItems: [
				{title: 'Edit'},
				{title: 'Delete'},
			],

			showManageDialog: false,
			dialogKeyTrades: 0,
			tradesOptions: {
				is_plumbing: false,
				is_hvac: false,
				is_electrician: false,
			},

			trades: [
				'Electrician',
				'HVAC',
				'Plumbing',
			],

			showInsuranceDialog: false,
			dialogKeyInsurance: 0,
			insurances: [
				{
					carrier: "Alice Smith",
					expires_date: "2021-12-15",
					file: "",
					notes: "General insurance",
					type: "General",
					valid_from_date: "2021-12-09",
				},

				{
					carrier: "Alice Smith",
					expires_date: "2021-12-15",
					file: "",
					notes: "General insurance",
					type: "General",
					valid_from_date: "2021-12-09",
				},
				{
					carrier: "Alice Smith",
					expires_date: "2021-12-15",
					file: "",
					notes: "General insurance",
					type: "General",
					valid_from_date: "2021-12-09",
				},

				{
					carrier: "Alice Smith",
					expires_date: "2021-12-15",
					file: "",
					notes: "General insurance",
					type: "General",
					valid_from_date: "2021-12-09",
				}, {
					carrier: "Alice Smith",
					expires_date: "2021-12-15",
					file: "",
					notes: "General insurance",
					type: "General",
					valid_from_date: "2021-12-09",
				},

				{
					carrier: "Alice Smith",
					expires_date: "2021-12-15",
					file: "",
					notes: "General insurance",
					type: "General",
					valid_from_date: "2021-12-09",
				},
				{
					carrier: "Alice Smith",
					expires_date: "2021-12-15",
					file: "",
					notes: "General insurance",
					type: "General",
					valid_from_date: "2021-12-09",
				},

				{
					carrier: "Alice Smith",
					expires_date: "2021-12-15",
					file: "",
					notes: "General insurance",
					type: "General",
					valid_from_date: "2021-12-09",
				}
			],

			showCertificateDialog: false,
			dialogKeyCertificate: 0,
			certificates: [
				{
					carrier: "John Doe",
					expires_date: "2021-12-15",
					file: "",
					notes: "Master plumber",
					type: "Certificate",
					valid_from_date: "2021-12-09",
				},
			]
		}),

		methods: {

			changeTrades(changeTrades) {

				this.tradesOptions = changeTrades;
			},

			openModal(index) {

				if (index === 0) {

					this.dialogKeyTrades++;
					this.showManageDialog = true;
					return
				}

				if (index === 1) {

					this.showInsuranceDialog = true;
					this.dialogKeyInsurance++;
					return;
				}

				this.showCertificateDialog = true;
				this.dialogKeyCertificate++;
			},

			processUpload(uplode) {

			},

			saveInsurance(itemInsurance) {

				this.insurances.push(itemInsurance);
			},

			saveCertificate(itemInsurance) {

				this.certificates.push(itemInsurance);
			}
		},

		computed: {

			isAnyTrades() {

				return this.tradesOptions.is_plumbing || this.tradesOptions.is_hvac || this.tradesOptions.is_electrician;
			}
		},
	}
</script>

<style lang="scss" scoped>

	.v-list-item--three-line .v-list-item__subtitle {
		white-space: initial;
		-webkit-line-clamp: 4 !important;
	}

	.subcontractors-section {
		min-height: 200px;
	}

</style>