<template>
	<v-dialog v-model="dialogDelete" max-width="500px">
		<v-card class="px-4">

			<v-card-subtitle class="py-10 text--primary text-justify">
				Cancelling your account will close your account, and remove all your data from the server. The action is
				not reversible. All data will be lost.
				To continue type 'I UNDERSTAND' in the box bellow, and click 'Cancel my account'
				name in the box below, and click CONFIRM.
			</v-card-subtitle>

			<v-card-text>
				<v-text-field
					dense
					v-model="inputDeleteText"
					label="Subcontractor name"
					outlined
				>
				</v-text-field>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					id="cancel-button"
					color="red darken-1"
					text
					@click.native="closeDelete"
				>
					Dismiss
				</v-btn>

				<v-btn
					id="confirm-delete-button"
					color="green darken-1"
					text
					@click.native="deleteItemConfirm"
				>
					Cancel my account
				</v-btn>
				<v-spacer></v-spacer>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		name: "ProfileCardDeleteDialog",

		props: {

			value: {
				Boolean,
				default: false
			},

			editedItemProp: {
				type: Object,
				required: true,
				default: {
					city: "",
					name: "",
					state: "",
					street: "",
					stripe_token: {},
					suite: "",
					zip: "",
				}
			},
		},

		data() {
			return {
				inputDeleteText: '',
				editedItem: this.editedItemProp,
			}
		},

		methods: {

			deleteItemConfirm() {

				//TO DO

				if ('I UNDERSTAND' == this.inputDeleteText) {

					this.$emit('deleteItemConfirm', this.editedItem);
					this.$store.dispatch('snackbar/showSnackbarMessage', {
						message: 'Card was deleted successfully',
						duration: 4000,
						mode: 'success'
					});
					this.closeDelete();
					return;
				}

				this.$store.dispatch('snackbar/showSnackbarMessage', {
					message: 'To delete card text must mach',
					duration: 6000,
					mode: 'fail'
				});

			},

			closeDelete() {

				this.dialogDelete = false;
			},
		},

		computed: {

			dialogDelete: {

				get() {
					return this.value
				},

				set(value) {
					this.$emit('input', value)
				}
			},

		},
	}
</script>

<style scoped>

</style>