<template>
	<v-data-table
		:headers="headers"
		:items="equipments"
		:search="search"
		sort-by="primary_contact_email"
		class="elevation-2"
	>
		<template v-slot:item.preferred_contractor="{ item }">
			<span v-if="item.preferred_contractor" style="color: green"> Preferred </span>
			<span v-if="item.preferred_contractor !== true" style="color: red"> Not Preferred </span>
		</template>

		<template v-slot:item.contact="{ item }">
			{{ item.first_name }} {{ item.last_name }}
		</template>

		<template v-slot:item.service_radius="{ item }">
			{{ item.service_radius }} miles
		</template>

		<template v-slot:top>
			<v-toolbar
				flat
			>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
				/>

				<v-spacer></v-spacer>

			</v-toolbar>
		</template>

		<template v-slot:item.actions="{ item, index }" v-if="isDialogMode === true">
			<v-btn
				:id="'delete-organization-button-' + index"
				small
				@click.stop="addToProject(item)"
			>
				Add to project
			</v-btn>
		</template>
		<template v-slot:no-data>
			<v-btn
				id="reload-data-button"
				color="primary"
				@click.native="initialize"
			>
				RELOAD
			</v-btn>
		</template>
	</v-data-table>
</template>

<script>

	export default {
		name: 'EquipmentTable',

		components: {},

		props: {

			isDialogMode: {
				Boolean,
				default: true
			},
		},

		data: () => ({

			dialog: false,
			dialogDelete: false,
			headers: [
				{text: 'Name', align: 'start', sortable: false, value: 'name'},
				{text: 'Location', align: 'start', sortable: false, value: 'location'},
				{text: 'Email', align: 'start', sortable: false, value: 'email'},
				{text: 'Phone', align: 'start', sortable: false, value: 'phone'},
				{text: 'Responsible', align: 'start', sortable: false, value: 'responsible'},
				{text: '', value: 'actions', sortable: false, align: 'end'},
			],
			equipments: [],
			defaultItem: {
				name: "",
				location: "",
				responsible: "",
				email: "",
				phone: "",
				icon: "",
			},
			editedIndex: -1,

			editedItem: {
				name: "",
				location: "",
				responsible: "",
				email: "",
				phone: "",
				icon: "",
			},
			search: '',
			dialogKeyCreateEditDialog: 0,
		}),

		methods: {

			initialize() {

				this.equipments = [
					{
						name: "Dewalt Drill",
						location: "123 South St. HappyTown, GA, 12345",
						responsible: "Johny Contractor",
						email: "johny@example.com",
						phone: "770-123-4567",
						icon: "mdi-tools",
						assigned: true,
					},
					{
						name: "Dewalt Drill",
						location: "123 South St. HappyTown, GA, 12345",
						responsible: "Johny Contractor",
						email: "johny@example.com",
						phone: "770-123-4567",
						icon: "mdi-tools",
						assigned: true,
					},
					{
						name: "Dewalt Drill",
						location: "123 South St. HappyTown, GA, 12345",
						responsible: "Johny Contractor",
						email: "johny@example.com",
						phone: "770-123-4567",
						icon: "mdi-tools",
						assigned: true,
					},
					{
						name: "Dewalt Drill",
						location: "123 South St. HappyTown, GA, 12345",
						responsible: "Johny Contractor",
						email: "johny@example.com",
						phone: "770-123-4567",
						icon: "mdi-tools",
						assigned: true,
					},
					{
						name: "Dewalt Drill",
						location: "123 South St. HappyTown, GA, 12345",
						responsible: "Johny Contractor",
						email: "johny@example.com",
						phone: "770-123-4567",
						icon: "mdi-tools",
						assigned: true,
					},
					{
						name: "Dewalt Drill",
						location: "123 South St. HappyTown, GA, 12345",
						responsible: "Johny Contractor",
						email: "johny@example.com",
						phone: "770-123-4567",
						icon: "mdi-tools",
						assigned: true,
					},
				]
			},

			editItem(item) {

				this.editedIndex = this.equipments.indexOf(item);
				this.dialogKeyCreateEditDialog++;
				this.editedItem = Object.assign({}, item);
				this.dialog = true;
			},

			openDeleteItemDialog(item) {

				this.editedIndex = this.equipments.indexOf(item);
				this.dialogKeyCreateEditDialog++;
				this.editedItem = Object.assign({}, item);
				this.dialogDelete = true;
			},

			goToSubcontractor(data, subcontractor) {
				this.$router.push(`/equipments/${subcontractor.index}`);
			},

			resetEditedItem(defaultItem) {
				this.editedItem = Object.assign({}, defaultItem);
			},

			save(editedItemFromForm) {

				if (this.editedIndex > -1) {

					Object.assign(this.equipments[this.editedIndex], editedItemFromForm)
					// this.close()
					return;
				}

				this.equipments.push(editedItemFromForm)
				// this.close()
			},

			closeDelete() {

				this.dialogDelete = false;

				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem);
					this.editedIndex = -1;
				})
			},

			deleteItemConfirm() {

				this.equipments.splice(this.editedIndex, 1);
				this.closeDelete();
			},

			addToProject(item) {

				this.$emit('save', item);
			}
		},

		created() {
			this.initialize();
		},
	}
</script>
