<template>
	<v-list>
		<v-list-item
			v-for="(item) in items"
			:key="item.title"
			:to="item.link"
			router
		>
			<v-list-item-icon>
				<v-icon color="black">{{ item.icon }}</v-icon>
			</v-list-item-icon>

			<v-list-item-content>
				<v-list-item-title>{{ item.title }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
	</v-list>
</template>

<script>

	export default {
		name: "TheLayoutWrapMenuList",

		data: () => ({
			items: [
				{
					icon: 'mdi-home-circle',
					title: 'Home',
					link: '/'
				},

				{
					icon: 'mdi-clock',
					title: 'Billing',
					link: '/billing'
				},

				{
					icon: 'mdi-account-circle-outline',
					title: 'My account',
					link: '/profile'
				},

				{
					icon: 'mdi-tooltip-account',
					title: 'Organizations',
					link: '/organizations'
				},

				{
					icon: 'mdi-folder-open-outline',
					title: 'Projects',
					link: '/projects'
				},

				{
					icon: 'mdi-account-group',
					title: 'Subcontractors',
					link: '/subcontractors'
				},

				{
					icon: 'mdi-tools',
					title: 'Equipments',
					link: '/equipment'
				},

				{
					icon: 'mdi-tablet-android',
					title: 'Scanners',
					link: '/scanners'
				},

				{
					icon: 'mdi-tag-multiple',
					title: 'Tags',
					link: '/tags'
				},

				{
					icon: 'mdi-settings-helper',
					title: 'Settings',
					link: '/settings'
				},
			]
		}),
	};

</script>

<style scoped>

</style>
