var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-10",attrs:{"id":"top-profile-page-info"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"height":"200","max-height":"250","elevation":hover ? 10 : 2,"outlined":""},nativeOn:{"click":function($event){return _vm.$router.push("/billing")}}},[_c('v-list-item',[_c('v-icon',{attrs:{"size":"130","color":hover ? 'gray' : 'black'}},[_vm._v(" mdi-account-circle-outline ")]),_c('v-list-item-content',[_c('v-card-text',[_c('h4',[_vm._v(" Lorem ipsum, inc. ")]),_vm._v(" "),_c('br'),_c('span',[_c('b',[_vm._v(" Primary contact: ")]),_vm._v(" John Smith ")]),_vm._v(" "),_c('br'),_c('span',[_c('b',[_vm._v(" Biling address: ")])]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" 1234 Happy Street Suite 1016 Atlanta, GA 30332 404-606-433 ")])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" Actions "),_c('v-icon',{attrs:{"right":"","dark":"","large":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"max-width":"400","height":"200","max-height":"250","elevation":hover ? 10 : 2,"outlined":""},nativeOn:{"click":function($event){return _vm.$router.push("/billing")}}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5 mb-1 font-weight-bold",staticStyle:{"color":"darkgreen"}},[_vm._v(" Billing ")]),_c('v-spacer'),_c('v-list-item-subtitle',{staticClass:"mt-4"},[_c('span',[_vm._v(" Billing Status: "),(_vm.billingStatus)?_c('b',{staticStyle:{"color":"green"}},[_vm._v(" Active ")]):_vm._e(),(_vm.billingStatus !== true)?_c('b',{staticStyle:{"color":"red"}},[_vm._v(" Inactive ")]):_vm._e()]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" Last charge: 21/10/21 ")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" Next charge: 21/11/21")])])],1),_c('v-icon',{staticClass:"pt-5 mt-2",attrs:{"size":"96","color":hover ? 'gray' : 'black'}},[_vm._v(" mdi-currency-usd ")])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"#1867C0"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push("/billing}")}}},[_c('u',[_vm._v(" Manage billing and payments ")])])],1)],1)]}}])})],1)],1)],1),_c('OrganizationTable')],1)}
var staticRenderFns = []

export { render, staticRenderFns }