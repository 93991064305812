<template>
	<div>
		<TheLayoutWrap>
			<transition name="fade" mode="out-in">
				<router-view></router-view>
			</transition>
		</TheLayoutWrap>
	</div>
</template>

<script>

	import TheLayoutWrap from "./layouts/TheLayoutWrap";

	export default {
		name: 'AppContent',
		components: {

			TheLayoutWrap
		},

		data: () => ({}),
	};
</script>
