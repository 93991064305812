<template>
	<v-data-table
		:headers="headers"
		:items="subcontractors"
		:search="search"
		sort-by="primary_contact_email"
		class="elevation-2"
		@click:row="goToSubcontractor"
	>
		<template v-slot:item.preferred_contractor="{ item }">
			<span v-if="item.preferred_contractor" style="color: green"> Preferred </span>
			<span v-if="item.preferred_contractor !== true" style="color: red"> Not Preferred </span>
		</template>

		<template v-slot:item.contact="{ item }">
			{{ item.first_name }} {{ item.last_name }}
		</template>

		<template v-slot:item.service_radius="{ item }">
			{{ item.service_radius }} miles
		</template>

		<template v-slot:top>
			<v-toolbar
				flat
			>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
				/>

				<v-spacer></v-spacer>

				<SubcontractorCreateEditDialog
					:edited-item-prop.sync="editedItem"
					:key="dialogKeyCreateEditDialog"
					:edited-index-prop="editedIndex"
					v-model="dialog"
					@resetEditedItem="resetEditedItem"
					@save="save"
				/>

				<SubcontractorDeleteDialog
					v-model="dialogDelete"
					:edited-item-prop.sync="editedItem"
					@deleteItemConfirm="deleteItemConfirm"
				/>

			</v-toolbar>
		</template>

		<template v-slot:item.actions="{ item, index }">

			<div v-if="isDialogMode == false">
				<v-icon
					:id="'edit-organization-button-' + index"
					small
					class="mr-2"
					@click.stop="editItem(item)"
				>
					mdi-pencil
				</v-icon>

				<v-icon
					:id="'delete-organization-button-' + index"
					small
					@click.stop="openDeleteItemDialog(item)"
				>
					mdi-delete
				</v-icon>
			</div>

			<div v-if="isDialogMode == true">
				<v-btn
					:id="'delete-organization-button-' + index"
					small
					@click.stop="addToProject(item)"
				>
					Add to project
				</v-btn>
			</div>
		</template>

		<template v-slot:no-data>
			<v-btn
				id="reload-data-button"
				color="primary"
				@click.native="initialize"
			>
				RELOAD
			</v-btn>
		</template>
	</v-data-table>
</template>

<script>

	import SubcontractorCreateEditDialog from "./SubcontractorCreateEditDialog";
	import SubcontractorDeleteDialog from "./SubcontractorDeleteDialog";

	export default {
		name: 'SubcontractorTable',

		components: {
			SubcontractorDeleteDialog,
			SubcontractorCreateEditDialog
		},

		props: {

			isDialogMode: {
				Boolean,
				default: false
			},
		},

		data: () => ({

			dialog: false,
			dialogDelete: false,
			headers: [
				{text: 'Name', align: 'start', sortable: false, value: 'name'},
				{text: 'Contact', align: 'start', sortable: false, value: 'contact'},
				{text: 'Email', align: 'start', sortable: false, value: 'email'},
				{text: 'Phone', align: 'start', sortable: false, value: 'phone'},
				{text: 'Trade', align: 'start', sortable: false, value: 'trade'},
				{text: 'Preferred', value: 'preferred_contractor'},
				{text: 'Range', align: 'start', sortable: false, value: `service_radius`},
				{text: '', value: 'actions', sortable: false, align: 'end'},
			],
			subcontractors: [],
			defaultItem: {
				name: '',
				first_name: '',
				last_name: '',
				email: '',
				phone: '',
				street: '',
				suite: '',
				city: '',
				state: '',
				zip: '',
				service_radius: '',
				preferred_contractor: false,
				blacklisted: false,
				trade: '',
				certification: '',
				type: '',
			},
			editedIndex: -1,

			editedItem: {
				name: '',
				first_name: '',
				last_name: '',
				email: '',
				phone: '',
				street: '',
				suite: '',
				city: '',
				state: '',
				zip: '',
				service_radius: '',
				preferred_contractor: false,
				blacklisted: false,
				trade: '',
				certification: '',
				type: '',
			},
			search: '',
			dialogKeyCreateEditDialog: 0,
		}),

		methods: {

			initialize() {

				this.subcontractors = [
					{
						name: 'Lorem ipsum Organization, inc.',
						first_name: 'Bill',
						last_name: 'Smith',
						email: 'someone@example.org',
						phone: '770 123 5678',
						service_radius: 75,
						preferred_contractor: true,
						street: 'Street 1',
						suite: 'Suite 1',
						city: 'Los Angeles',
						state: 'LA',
						zip: 1111,
						trade: 'Electrician',
						is_clear_to_work: false,
					},
					{
						name: 'Lorem ipsum Organization, inc.',
						first_name: 'Bill',
						last_name: 'Smith',
						email: 'someone@example.org',
						phone: '770 123 5678',
						service_radius: 75,
						preferred_contractor: true,
						street: 'Street 1',
						suite: 'Suite 1',
						city: 'Los Angeles',
						state: 'LA',
						zip: 1111,
						trade: 'Electrician',
						is_clear_to_work: true,
					},
					{
						name: 'Lorem ipsum Organization, inc.',
						first_name: 'Bill',
						last_name: 'Smith',
						email: 'someone@example.org',
						phone: '770 123 5678',
						service_radius: 75,
						preferred_contractor: true,
						street: 'Street 1',
						suite: 'Suite 1',
						city: 'Los Angeles',
						state: 'LA',
						zip: 1111,
						trade: 'Electrician'
					},
					{
						name: 'Lorem ipsum Organization, inc.',
						first_name: 'Bill',
						last_name: 'Smith',
						email: 'someone@example.org',
						phone: '770 123 5678',
						service_radius: 75,
						preferred_contractor: true,
						street: 'Street 1',
						suite: 'Suite 1',
						city: 'Los Angeles',
						state: 'LA',
						zip: 1111,
						trade: 'Electrician'
					},
					{
						name: 'Lorem ipsum Organization, inc.',
						first_name: 'Bill',
						last_name: 'Smith',
						email: 'someone@example.org',
						phone: '770 123 5678',
						service_radius: 75,
						preferred_contractor: true,
						street: 'Street 1',
						suite: 'Suite 1',
						city: 'Los Angeles',
						state: 'LA',
						zip: 1111,
						trade: 'Electrician'
					},
					{
						name: 'Lorem ipsum Organization, inc.',
						first_name: 'Bill',
						last_name: 'Smith',
						email: 'someone@example.org',
						phone: '770 123 5678',
						service_radius: 75,
						preferred_contractor: true,
						street: 'Street 1',
						suite: 'Suite 1',
						city: 'Los Angeles',
						state: 'LA',
						zip: 1111,
						trade: 'Electrician'
					},
				];
			},

			editItem(item) {

				this.editedIndex = this.subcontractors.indexOf(item);
				this.dialogKeyCreateEditDialog++;
				this.editedItem = Object.assign({}, item);
				this.dialog = true;
			},

			openDeleteItemDialog(item) {

				this.editedIndex = this.subcontractors.indexOf(item);
				this.dialogKeyCreateEditDialog++;
				this.editedItem = Object.assign({}, item);
				this.dialogDelete = true;
			},

			goToSubcontractor(data, subcontractor) {

				if (this.isDialogMode == false) {
					this.$router.push(`/subcontractors/${subcontractor.index}`);
				}
			},

			resetEditedItem(defaultItem) {

				this.editedIndex = -1;
				this.editedItem = Object.assign({}, defaultItem);
			},

			save(editedItemFromForm) {

				if (this.editedIndex > -1) {

					Object.assign(this.subcontractors[this.editedIndex], editedItemFromForm);
					this.$store.dispatch('snackbar/showSnackbarMessage', {
						message: 'Subcontractor was edited successfully',
						duration: 4000,
						mode: 'success'
					});
					return;
				}

				this.subcontractors.push(editedItemFromForm);
				this.$store.dispatch('snackbar/showSnackbarMessage', {
					message: 'Subcontractor was added successfully',
					duration: 4000,
					mode: 'success'
				});
			},

			closeDelete() {

				this.dialogDelete = false;

				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem);
					this.editedIndex = -1;
				})
			},

			deleteItemConfirm() {

				this.subcontractors.splice(this.editedIndex, 1);
				this.closeDelete();
			},

			addToProject(item) {

				this.$emit('save', item);
			}
		},

		created() {
			this.initialize();
		},
	}
</script>
