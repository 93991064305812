import $axios from '../plugins/axios'

class AuthService {

	getMe() {

		return $axios.get('me')
			.then((res) => {

				return res.data;
			}).catch((error) => {

				return new Promise((resolve, reject) => {
					return reject(error)
				})
			})
	}

	register(path, user) {

		return $axios.post(path,user)
			.then(res => {

			return res.data;
		})
	}

	// register(data) {
	//
	// 	$axios.post(data.path, data.user)
	// 		.then(res => {
	//
	// 			this.login(user);
	//
	// 			this.$store.dispatch('snackbar/showSnackbarMessage ',
	// 				{message: 'Registered successfully.', duration: 4000, mode: 'success'});
	// 		})
	// 		.catch(error => {
	//
	// 			this.$store.dispatch('snackbar/showSnackbarMessage ',
	// 				{message: error.response.data, duration: 4000, mode: 'fail'});
	// 		});
	// },

	login(user) {

		return $axios.post('auth/login', {
			email: user.email,
			password: user.password
		}).then((res) => {

			return res.data;
		}).catch((error) => {

			return new Promise((resolve, reject) => {
				return reject(error)
			})
		})
	}

	loginAfterRegistration(user) {

		return $axios.post('auth/login', {
			email: user.email,
			password: user.password,
			after_registration: true
		}).then((res) => {

			return res.data;
		}).catch((error) => {

			return new Promise((resolve, reject) => {
				return reject(error)
			})
		})
	}

	signUp(user) {

		return $axios.post('users', user)
			.then(res => {

				return res
			});
	}

	impersonate(userID) {

		return $axios.post(`auth/impersonate`, {user_id: userID})
			.then(res => {

				return res.data;
			});
	}

}

export default new AuthService();