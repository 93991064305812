<template>
	<v-data-table
		:headers="headers"
		:items="projects"
		:search="search"
		sort-by="primary_contact_email"
		class="elevation-2"
		@click:row="goToProject"
	>

		<template v-slot:item.active="{ item }">
			<span v-if="item.active" style="color: green"> Active </span>
			<span v-if="item.active !== true" style="color: red"> Inactive </span>
		</template>

		<template v-slot:item.totalAmount="{ item }">
			$
			{{ item.totalAmount }}
		</template>

		<template v-slot:top>
			<v-toolbar
				flat
			>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
				/>

				<v-spacer></v-spacer>

				<ProjectCreateEditDialog
					:edited-item-prop.sync="editedItem"
					:key="dialogKeyCreateEditDialog"
					:edited-index-prop="editedIndex"
					v-model="dialog"
					@resetEditedItem="resetEditedItem"
					@save="save"
				/>

				<ProjectDeleteDialog
					v-model="dialogDelete"
					:edited-item-prop.sync="editedItem"
					@deleteItemConfirm="deleteItemConfirm"
				/>

			</v-toolbar>
		</template>

		<template v-slot:item.actions="{ item, index }">
			<v-icon
				:id="'edit-organization-button-' + index"
				small
				class="mr-2"
				@click.stop="editItem(item)"
			>
				mdi-pencil
			</v-icon>

			<v-icon
				:id="'delete-organization-button-' + index"
				small
				@click.stop="openDeleteItemDialog(item)"
			>
				mdi-delete
			</v-icon>
		</template>
		<template v-slot:no-data>
			<v-btn
				id="reload-data-button"
				color="primary"
				@click.native="initialize"
			>
				RELOAD
			</v-btn>
		</template>
	</v-data-table>
</template>

<script>

	import ProjectCreateEditDialog from "./ProjectCreateEditDialog";
	import ProjectDeleteDialog from "./ProjectDeleteDialog";

	export default {
		name: 'ProjectTable',

		components: {
			ProjectDeleteDialog,
			ProjectCreateEditDialog
		},

		data: () => ({

			dialog: false,
			dialogDelete: false,
			headers: [
				{text: 'ID', align: 'start', sortable: false, value: 'id'},
				{text: 'Name', align: 'start', sortable: false, value: 'name'},
				{text: 'Creation', align: 'start', sortable: false, value: 'originDate'},
				{text: 'Target', align: 'start', sortable: false, value: 'targetDate'},
				{text: 'Price', align: 'start', sortable: false, value: `totalAmount`},
				{text: 'Active', value: 'active'},
				{text: '', value: 'actions', sortable: false, align: 'end'},
			],
			projects: [],
			defaultItem: {
				name: '',
				originDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				targetDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				totalAmount: null,
				primary_contact: '',
				project_manager: '',
				email: '',
				street: '',
				suite: '',
				city: '',
				state: '',
				zip: '',
				trade: ''
			},
			editedIndex: -1,

			editedItem: {
				name: '',
				originDate: null,
				targetDate: null,
				totalAmount: 1250000,
				primary_contact: '',
				project_manager: '',
				email: '',
				street: '',
				suite: '',
				city: '',
				state: '',
				zip: '',
				trade: ''
			},
			search: '',
			dialogKeyCreateEditDialog: 0,
		}),

		methods: {

			initialize() {

				this.projects = [
					{
						id: 12345,
						name: 'Lorem ipsum Organization, inc.',
						originDate: '20.12.2021',
						targetDate: '24.12.2022',
						email: 'someone@example.org',
						totalAmount: 1250000,
						active: true,

						primary_contact: 'Test',
						project_manager: 'Test',
						street: 'Test',
						suite: 'Test',
						city: 'Test',
						state: 'Test',
						zip: 1111,
						trade: 'Test'

					},
					{
						id: 12346,
						name: 'Lorem ipsum Organization, inc.',
						originDate: '21.12.2021',
						targetDate: '24.12.2022',
						email: 'someone@example.org',
						totalAmount: 1250000,
						active: true,
						primary_contact: 'Test',
						project_manager: 'Test',
						street: 'Test',
						suite: 'Test',
						city: 'Test',
						state: 'Test',
						zip: 1111,
						trade: 'Test'
					},
					{
						id: 12347,
						name: 'Eclair',
						originDate: '24.12.2021',
						targetDate: '24.12.2022',
						email: 'someone@example.org',
						totalAmount: 1250000,
						active: true,
						primary_contact: 'Test',
						project_manager: 'Test',
						street: 'Test',
						suite: 'Test',
						city: 'Test',
						state: 'Test',
						zip: 1111,
						trade: 'Test'
					},
					{
						id: 12348,
						name: 'Cupcake',
						originDate: '24.12.2021',
						targetDate: '24.12.2022',
						email: 'someone@example.org',
						totalAmount: 1250000,
						active: true,
						primary_contact: 'Test',
						project_manager: 'Test',
						street: 'Test',
						suite: 'Test',
						city: 'Test',
						state: 'Test',
						zip: 1111,
						trade: 'Test'
					},
					{
						id: 12349,
						name: 'Gingerbread',
						originDate: '24.12.2021',
						targetDate: '24.12.2022',
						email: 'someone@example.org',
						totalAmount: 1250000,
						active: true,
						primary_contact: 'Test',
						project_manager: 'Test',
						street: 'Test',
						suite: 'Test',
						city: 'Test',
						state: 'Test',
						zip: 1111,
						trade: 'Test'
					},
					{
						id: 12350,
						name: 'Jelly bean',
						originDate: '24.12.2021',
						targetDate: '24.12.2022',
						email: 'someone@example.org',
						totalAmount: 1250000,
						active: true,
						primary_contact: 'Test',
						project_manager: 'Test',
						street: 'Test',
						suite: 'Test',
						city: 'Test',
						state: 'Test',
						zip: 1111,
						trade: 'Test'
					},
					{
						id: 12351,
						name: 'Lollipop',
						originDate: '24.12.2021',
						targetDate: '24.12.2022',
						email: 'someone@example.org',
						totalAmount: 1250000,
						active: true,
						primary_contact: 'Test',
						project_manager: 'Test',
						street: 'Test',
						suite: 'Test',
						city: 'Test',
						state: 'Test',
						zip: 1111,
						trade: 'Test'
					},
					{
						id: 12352,
						name: 'Honeycomb',
						originDate: '24.12.2021',
						targetDate: '24.12.2022',
						email: 'someone@example.org',
						totalAmount: 1250000,
						active: true,
						primary_contact: 'Test',
						project_manager: 'Test',
						street: 'Test',
						suite: 'Test',
						city: 'Test',
						state: 'Test',
						zip: 1111,
						trade: 'Test'
					},
					{
						id: 12353,
						name: 'Donut',
						originDate: '24.12.2021',
						targetDate: '24.12.2022',
						email: 'someone@example.org',
						totalAmount: 1250000,
						active: true,
						primary_contact: 'Test',
						project_manager: 'Test',
						street: 'Test',
						suite: 'Test',
						city: 'Test',
						state: 'Test',
						zip: 1111,
						trade: 'Test'
					},
					{
						id: 12354,
						name: 'KitKat',
						originDate: '24.12.2021',
						targetDate: '24.12.2022',
						email: 'someone@example.org',
						totalAmount: 1250000,
						active: true,
						primary_contact: 'Test',
						project_manager: 'Test',
						street: 'Test',
						suite: 'Test',
						city: 'Test',
						state: 'Test',
						zip: 1111,
						trade: 'Test'
					},
				];
			},

			// async getAllProjects() {
			//
			// 	this.projects = await ProjectsService.getProjects();
			// },

			assignOrganization(organization) {

				this.editedItem = Object.assign({}, organization);
				this.editedItem.primary_contact_first_name = organization.primary_contact.first_name;
				this.editedItem.primary_contact_last_name = organization.primary_contact.last_name;
				this.editedItem.primary_contact_title_id = organization.primary_contact.title_id;
				this.editedItem.primary_contact_phone_number = organization.primary_contact.phone;
				this.editedItem.primary_contact_email = organization.primary_contact.email;
			},

			editItem(item) {

				this.editedIndex = this.projects.indexOf(item);
				this.dialogKeyCreateEditDialog++;
				this.editedItem = Object.assign({}, item);
				this.dialog = true;
			},

			openDeleteItemDialog(item) {

				this.editedIndex = this.projects.indexOf(item);
				this.dialogKeyCreateEditDialog++;
				this.editedItem = Object.assign({}, item);
				this.dialogDelete = true;
			},

			goToProject(data, project) {
				this.$router.push(`/projects/${project.index}`);
			},

			resetEditedItem(defaultItem) {

				this.editedIndex = -1;
				this.editedItem = Object.assign({}, defaultItem);
			},

			// close() {
			//
			// 	this.dialog = false;
			// 	this.$nextTick(() => {
			// 		this.editedItem = Object.assign({}, this.defaultItem)
			// 		this.editedIndex = -1
			// 	})
			// },

			save(editedItemFromForm) {

				if (this.editedIndex > -1) {

					Object.assign(this.projects[this.editedIndex], editedItemFromForm);
					this.$store.dispatch('snackbar/showSnackbarMessage', {
						message: 'Project was edited successfully',
						duration: 4000,
						mode: 'success'
					});
					return;
				}

				this.projects.push(editedItemFromForm);
				this.$store.dispatch('snackbar/showSnackbarMessage', {
					message: 'Project was added successfully',
					duration: 4000,
					mode: 'success'
				});
			},

			closeDelete() {
				this.dialogDelete = false
				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem)
					this.editedIndex = -1
				})
			},

			deleteItemConfirm() {
				this.projects.splice(this.editedIndex, 1)
				this.closeDelete()
			},
		},

		created() {
			this.initialize();
		},
	}
</script>
