var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.equipments,"search":_vm.search,"sort-by":"primary_contact_email"},scopedSlots:_vm._u([{key:"item.preferred_contractor",fn:function(ref){
var item = ref.item;
return [(item.preferred_contractor)?_c('span',{staticStyle:{"color":"green"}},[_vm._v(" Preferred ")]):_vm._e(),(item.preferred_contractor !== true)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" Not Preferred ")]):_vm._e()]}},{key:"item.contact",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]}},{key:"item.service_radius",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.service_radius)+" miles ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1)]},proxy:true},(_vm.isDialogMode === true)?{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{attrs:{"id":'delete-organization-button-' + index,"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.addToProject(item)}}},[_vm._v(" Add to project ")])]}}:null,{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"id":"reload-data-button","color":"primary"},nativeOn:{"click":function($event){return _vm.initialize.apply(null, arguments)}}},[_vm._v(" RELOAD ")])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }