<template>
	<v-dialog v-model="dialogDelete" max-width="800px">
		<v-card class="py-4">

			<v-card-title class="py-7 d-flex justify-center">
				<h3> Upload insurance</h3>
			</v-card-title>

			<v-card-text>
				<v-row>
					<v-col
						cols="12"
						sm="12"
						md="6"
						class="py-0"
					>
						<v-text-field
							dense
							v-model="editedItem.type"
							required
							:error-messages="typeError"
							@blur="$v.editedItem.type.$touch()"
							label="Insurance Type"
							outlined
						>
						</v-text-field>
					</v-col>

					<v-col
						cols="12"
						sm="12"
						md="6"
						class="py-0"
					>
						<v-text-field
							dense
							v-model="editedItem.carrier"
							required
							:error-messages="carrierError"
							@blur="$v.editedItem.carrier.$touch()"
							label="Insurance Carrier"
							outlined
						>
						</v-text-field>
					</v-col>

					<v-col
						cols="12"
						sm="12"
						md="6"
						class="py-0"
					>
						<v-menu
							v-model="menuValidDate"
							:close-on-content-click="false"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="editedItem.valid_from_date"
									label="Valid from"
									prepend-icon="mdi-calendar"
									readonly
									:error-messages="validFromDateError"
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="editedItem.valid_from_date"
								@input="menuValidDate = false"

							></v-date-picker>
						</v-menu>
					</v-col>

					<v-col
						cols="12"
						sm="12"
						md="6"
						class="py-0"
					>

						<v-menu
							v-model="menuExpiresDate"
							:close-on-content-click="false"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="editedItem.expires_date"
									label="Expires"
									prepend-icon="mdi-calendar"
									readonly
									:error-messages="expiresDateError"
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="editedItem.expires_date"
								@input="menuExpiresDate = false"

							></v-date-picker>
						</v-menu>
					</v-col>

					<v-col
						cols="12"
						sm="12"
						md="6"
						class="py-0"
					>
						<DragAndDropUploadFile :dialog="true" :multiple="true" @filesUploaded="processUpload($event)"/>

						<!--						<v-text-field-->
						<!--							dense-->
						<!--							v-model="editedItem.file"-->
						<!--							label="file"-->
						<!--							outlined-->
						<!--						>-->
						<!--						</v-text-field>-->
					</v-col>

					<v-col
						cols="12"
						sm="12"
						md="6"
						class="py-0"
					>
						<v-textarea
							v-model="editedItem.notes"
							outlined
							required
							:error-messages="notesError"
							@blur="$v.editedItem.notes.$touch()"
							rows="6"
							name="input-7-4"
							label="Notes"
						></v-textarea>
					</v-col>

				</v-row>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					id="cancel-button"
					color="red darken-1"
					text
					@click.native="closeDialog"
				>
					close
				</v-btn>

				<v-btn
					id="create-edit-button"
					color="green darken-1"
					style="color:white"
					@click.native="save"
					:disabled="$v.$anyError"
					text
				>
					Save
				</v-btn>

				<v-spacer></v-spacer>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import {validationMixin} from "vuelidate";
	import {email, minValue, required} from "vuelidate/lib/validators";
	import DragAndDropUploadFile from "../Base/DragAndDropUploadFile";

	export default {
		name: "SubcontractorAddInsuranceDialog",
		components: {DragAndDropUploadFile},
		props: {

			value: {
				Boolean,
				default: false
			},
		},

		mixins: [validationMixin],

		validations: {

			editedItem: {
				type: {required},
				carrier: {required},
				valid_from_date: {required},
				expires_date: {required},
				notes: {required},
			}
		},

		data() {
			return {
				menuValidDate: false,
				menuExpiresDate: false,

				editedItem: {
					type: '',
					carrier: '',
					valid_from_date: '',
					expires_date: '',
					file: '',
					notes: '',
				}
			}
		},

		methods: {

			save() {

				this.$v.$touch();

				if (this.$v.$anyError) {

					this.$store.dispatch('snackbar/showSnackbarMessage', {
						message: 'Please enter valid data',
						duration: 6000,
						mode: 'fail'
					});
					return
				}

				this.$emit('save', this.editedItem);
				this.closeDialog();
			},

			closeDialog() {

				this.dialogDelete = false;
			},

			processUpload() {

			}
		},

		computed: {

			dialogDelete: {

				get() {
					return this.value
				},

				set(value) {
					this.$emit('input', value)
				}
			},

			typeError() {

				const errors = []
				if (!this.$v.editedItem.type.$dirty) {
					return errors
				}
				!this.$v.editedItem.type.required && errors.push('Typeis required.')
				return errors
			},

			carrierError() {

				const errors = []
				if (!this.$v.editedItem.carrier.$dirty) {
					return errors
				}
				!this.$v.editedItem.carrier.required && errors.push('Carrier is required.')
				return errors
			},

			validFromDateError() {

				const errors = []
				if (!this.$v.editedItem.valid_from_date.$dirty) {
					return errors
				}
				!this.$v.editedItem.valid_from_date.required && errors.push('From date is required.')
				return errors
			},

			expiresDateError() {

				const errors = []
				if (!this.$v.editedItem.expires_date.$dirty) {
					return errors
				}
				!this.$v.editedItem.expires_date.required && errors.push('Expires date is required.')
				return errors
			},

			notesError() {

				const errors = []
				if (!this.$v.editedItem.notes.$dirty) {
					return errors
				}
				!this.$v.editedItem.notes.required && errors.push('Note is required.')
				return errors
			},
		},
	}
</script>

<style scoped>

</style>