<template>
	<div>
		<v-row class="d-flex justify-center align-center">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
				<h1 class="my-3"> Tags</h1>
			</v-col>
		</v-row>

		<v-data-table
			:headers="headers"
			:items="tags"
			:search="search"
			sort-by="primary_contact_email"
			class="elevation-2"
		>

			<template v-slot:top>
				<v-toolbar
					flat
				>
					<v-text-field
						v-model="search"
						append-icon="mdi-magnify"
						label="Search"
						single-line
						hide-details
					/>

					<v-spacer></v-spacer>

					<v-btn
						color="primary"
						dark
						class="mb-2 px-8"
						:color="'green'"
						@click.native="openAddTagDialog"
					>
						New
					</v-btn>

					<TagAddDialog
						:key="tagKey + '-tag-add'"
						v-model="tagDialog"
						@save="save"
					/>

				</v-toolbar>
			</template>

			<template v-slot:no-data>
				<v-btn
					id="reload-data-button"
					color="primary"
					@click.native="initialize"
				>
					RELOAD
				</v-btn>
			</template>
		</v-data-table>

	</div>
</template>

<script>

	import TagAddDialog from "./TagAddDialog";

	export default {
		name: 'TagTable',

		components: {
			TagAddDialog

		},

		data: () => ({

			tagDialog: false,
			tagKey: 0,
			headers: [
				{text: 'ID', align: 'start', sortable: false, value: 'id'},
				{text: 'Assigned', align: 'start', sortable: false, value: 'assigned'},
				{text: 'Email', align: 'start', sortable: false, value: 'assigned_email'},

				{text: '', value: 'actions', sortable: false, align: 'end'},
			],
			tags: [
				{
					id: '12345',
					assigned: true,
					assigned_email: 'admin@example.com',
				},
				{
					id: '12346',
					assigned: false,
					assigned_email: '',
				},
				{
					id: '12347',
					assigned: false,
					assigned_email: '',
				}
			],

			editedItem: {
				id: '',
				assigned: false,
				assigned_email: ''
			},
			search: '',
			dialogKeyCreateEditDialog: 0,
		}),

		methods: {

			openAddTagDialog() {

				this.tagKey++;
				this.tagDialog = true;
			},

			save(editedItemFromForm) {

				if (this.editedIndex > -1) {

					Object.assign(this.tags[this.editedIndex], editedItemFromForm);
					this.$store.dispatch('snackbar/showSnackbarMessage', {
						message: 'Project was edited successfully',
						duration: 4000,
						mode: 'success'
					});
					return;
				}

				this.tags.push(editedItemFromForm);
				this.$store.dispatch('snackbar/showSnackbarMessage', {
					message: 'Project was added successfully',
					duration: 4000,
					mode: 'success'
				});
			},

			closeDelete() {
				this.dialogDelete = false
				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem)
					this.editedIndex = -1
				})
			},

			deleteItemConfirm() {
				this.tags.splice(this.editedIndex, 1)
				this.closeDelete()
			},
		},

	}
</script>
