var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-10",attrs:{"id":"top-profile-page-info"}},[_c('v-row',[_c('v-col',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"height":"220","max-height":"270","elevation":hover ? 10 : 2,"outlined":""}},[_c('v-list-item',[_c('v-icon',{attrs:{"size":"130","color":hover ? 'gray' : 'black'}},[_vm._v(" mdi-account-circle-outline ")]),_c('v-list-item-content',[_c('v-row',[_c('v-col',[_c('h2',[_vm._v(" Lorem ipsum project ")]),_vm._v(" "),_c('br'),_c('p',[_c('b',[_vm._v(" Primary Contact: ")]),_vm._v(" John Smith "),_c('a',[_vm._v(" email")])]),_c('span',[_c('b',[_vm._v(" Contact address: ")])]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" 1234 Happy Street Suite 1016 Atlanta, GA 30332 404-606-433 ")])]),_c('v-col',{staticClass:"d-flex align-end "},[_c('v-spacer'),_c('div',[_c('b',[_vm._v(" Service Radius: ")]),_c('span',{staticStyle:{"width":"40px","height":"40px","border":"1px solid","padding":"10px","margin-left":"10px"}},[_vm._v(" 350 ")])])],1),_c('v-spacer')],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" Actions "),_c('v-icon',{attrs:{"right":"","dark":"","large":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();return _vm.openModal(index)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)],1)],1)]}}])})],1)],1)],1),_c('div',{staticClass:"subcontractors-section my-7"},[_c('h2',[_vm._v(" Trades ")]),(_vm.isAnyTrades == false)?_c('p',[_vm._v(" You don't have any subcontractors yet. Click Actions > Add subcontractors to add one")]):_vm._e(),_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-sheet',{staticClass:"py-4 px-1",attrs:{"elevation":"10"}},[_c('v-chip-group',{attrs:{"disabled":false,"active-class":"primary--text"}},[(_vm.tradesOptions.is_electrician)?_c('v-chip',[_vm._v(" Electrician ")]):_vm._e(),(_vm.tradesOptions.is_hvac)?_c('v-chip',[_vm._v(" HVAC ")]):_vm._e(),(_vm.tradesOptions.is_plumbing)?_c('v-chip',[_vm._v(" Plumbing ")]):_vm._e()],1)],1)],1)],1)],1),_c('div',{staticClass:"subcontractors-section my-7"},[_c('h2',[_vm._v(" Certification ")]),(_vm.certificates.length === 0)?_c('p',[_vm._v(" You don't have any Certification yet. Click Actions > Add Certification to add one")]):_vm._e(),_c('v-row',_vm._l((_vm.certificates),function(certificate,index){return _c('v-col',{key:index + '-certificate',attrs:{"cols":12,"lg":"4","md":"12","sm":"12","xs":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"max-width":"500","height":"200","max-height":"250","elevation":hover ? 10 : 2,"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5 mb-1 font-weight-bold"},[_vm._v(" "+_vm._s(certificate.type)+" ")]),_c('v-spacer'),_c('v-list-item-subtitle',[_c('p',[_c('span',[_vm._v(" "+_vm._s(certificate.carrier)+" ")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" "+_vm._s(certificate.valid_from_date)+" ")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" "+_vm._s(certificate.expires_date)+" ")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" "+_vm._s(certificate.notes)+" ")]),_vm._v(" "),_c('br')])])],1)],1),_c('v-divider',{staticClass:"pa-0"}),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"#1867C0","small":""}},[_c('u',[_vm._v(" Download file ")])]),_c('v-spacer'),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_vm._v(" Actions "),_c('v-icon',{attrs:{"right":"","dark":"","large":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.cardActionItems),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)],1)],1)]}}],null,true)})],1)}),1)],1),_c('div',{staticClass:"equipments-section my-7"},[_c('h2',[_vm._v(" Insurance")]),(_vm.insurances.length === 0)?_c('p',[_vm._v(" You don't have any insurances yet. Click Actions > Add insurances to add one")]):_vm._e(),_c('v-row',_vm._l((_vm.insurances),function(insurance,index){return _c('v-col',{key:index + '-insurance',attrs:{"cols":12,"lg":"4","md":"12","sm":"12","xs":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"max-width":"500","height":"200","max-height":"250","elevation":hover ? 10 : 2,"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5 mb-1 font-weight-bold"},[_vm._v(" "+_vm._s(insurance.type)+" ")]),_c('v-spacer'),_c('v-list-item-subtitle',[_c('p',[_c('span',[_vm._v(" "+_vm._s(insurance.carrier)+" ")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" "+_vm._s(insurance.valid_from_date)+" ")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" "+_vm._s(insurance.expires_date)+" ")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" "+_vm._s(insurance.notes)+" ")]),_vm._v(" "),_c('br')])])],1)],1),_c('v-divider',{staticClass:"pa-0"}),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"#1867C0","small":""}},[_c('u',[_vm._v(" Download file ")])]),_c('v-spacer'),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_vm._v(" Actions "),_c('v-icon',{attrs:{"right":"","dark":"","large":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.cardActionItems),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)],1)],1)]}}],null,true)})],1)}),1)],1),_c('SubcontractorManageTradesDialog',{key:_vm.dialogKeyTrades + '-trades',attrs:{"edited-item-prop":_vm.tradesOptions},on:{"update:editedItemProp":function($event){_vm.tradesOptions=$event},"update:edited-item-prop":function($event){_vm.tradesOptions=$event},"changeTrades":_vm.changeTrades},model:{value:(_vm.showManageDialog),callback:function ($$v) {_vm.showManageDialog=$$v},expression:"showManageDialog"}}),_c('SubcontractorAddInsuranceDialog',{key:_vm.dialogKeyInsurance + '-insurance',on:{"save":_vm.saveInsurance},model:{value:(_vm.showInsuranceDialog),callback:function ($$v) {_vm.showInsuranceDialog=$$v},expression:"showInsuranceDialog"}}),_c('SubcontractorAddCertificateDialog',{key:_vm.dialogKeyCertificate + '-certificate',on:{"save":_vm.saveCertificate},model:{value:(_vm.showCertificateDialog),callback:function ($$v) {_vm.showCertificateDialog=$$v},expression:"showCertificateDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }