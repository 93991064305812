<template>
	<v-row
		class="d-flex justify-center align-center mb-6 auth-container"
		id="login-main"
		style="background: linear-gradient(-135deg,#ffffff,#dcdcdc);"
	>
		<v-col
			cols="12"
			sm="10"
			md="8"
			lg="6"
		>
			<v-card class="py-10">

				<v-card-title class="d-flex justify-center">
					<h2 class="text-h4">Register </h2> <br>
				</v-card-title>

				<v-card-text>
					<v-container>
						<v-text-field
							class="auth-form__field"
							required
							placeholder="First Name"
							v-model="regForm.first_name"
							:error-messages="firstNameErrors"
							@blur="$v.regForm.first_name.$touch()"
						/>

						<v-text-field
							class="auth-form__field"
							required
							placeholder="Last Name"
							v-model="regForm.last_name"
							:error-messages="lastNameErrors"
							@blur="$v.regForm.last_name.$touch()"
						/>

						<v-text-field
							class="auth-form__field"
							required
							placeholder="Email"
							v-model="regForm.email"
							@blur="$v.regForm.email.$touch()"
							:error-messages="emailErrors"
						/>

						<v-text-field
							class="auth-form__field"
							required
							type="password"
							placeholder="Password"
							v-model="regForm.password"
							@blur="$v.regForm.password.$touch()"
							:error-messages="passwordErrors"
						/>

						<v-card-actions>
							<v-row class="d-flex justify-space-around mt-5">

								<v-btn
									@click="goBack()"
									id="go-back-button"
								>
									GO BACK
								</v-btn>

								<v-btn
									id="register-button"
									color="success"
									:disabled="$v.$anyError"
									@click.native="register"
									:loading="buttonLoadingSpinner"
								>
									REGISTER
								</v-btn>
							</v-row>
						</v-card-actions>

					</v-container>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
	import {email, required} from "vuelidate/lib/validators";
	import {validationMixin} from "vuelidate";

	export default {
		name: 'AuthRegister',

		components: {},
		mixins: [validationMixin],

		validations: {

			regForm: {
				first_name: {required},
				last_name: {required},
				email: {required, email},
				password: {required},
			},
		},

		data() {
			return {
				regForm: {
					first_name: null,
					last_name: null,
					email: null,
					password: null
				},
				buttonLoadingSpinner: false,
			}
		},

		methods: {

			goBack() {
				this.$router.push('/login');
			},

			login(user) {

				let _this = this;

				this.$store.dispatch('auth/loginAfterRegistration', {
					email: user.email,
					password: user.password,
				})
					.then((res) => {

						//Set axios Authorization header
						this.$axios.defaults.headers.common['Authorization'] = `Bearer ${res.auth_token}`;
						this.$router.push('/dashboard');
					})
					.catch((error) => {

						_this.$store.dispatch('snackbar/showSnackbarMessage ',
							{message: error.response.data, duration: 4000, mode: 'fail'});
					});
			},

			register(user) {

				this.buttonLoadingSpinner = true;
				this.$v.$touch();

				if (this.$v.$anyError) {

					this.$store.dispatch('snackbar/showSnackbarMessage', {
						message: 'Please enter valid data',
						duration: 6000,
						mode: 'fail'
					});
					this.buttonLoadingSpinner = false;
					return;
				}

				let path = 'register';
				if (this.token) path += '/' + this.token;

				// AuthService.register(path, user)
				// 	.then(res => {
				// 		this.login(user);
				//
				// 		this.$store.dispatch('snackbar/showSnackbarMessage ',
				// 					{message: 'Registered successfully.', duration: 4000, mode: 'success'});
				// 	});

				this.$axios.post(path, user)
					.then(res => {

						this.login(user);

						this.$store.dispatch('snackbar/showSnackbarMessage ',
							{message: 'Registered successfully.', duration: 4000, mode: 'success'});
						this.buttonLoadingSpinner = false;
					})
					.catch(error => {

						this.$store.dispatch('snackbar/showSnackbarMessage ',
							{message: error.response.data, duration: 4000, mode: 'fail'});
						this.buttonLoadingSpinner = false;
					});
			},

			getTokenInvitation(token) {

				this.$axios.get(`/invites/${token}`).then(res => {

					this.isInvite = true;
					this.regForm.first_name = res.data.first_name;
					this.regForm.last_name = res.data.last_name;
					this.regForm.email = res.data.email;
				}).catch(error => {

					this.$store.dispatch('snackbar/showSnackbarMessage ',
						{message: error.response.data, duration: 4000, mode: 'fail'});
				});
			},

		},

		computed: {

			emailErrors() {

				const errors = [];
				if (!this.$v.regForm.email.$dirty) return errors;
				!this.$v.regForm.email.email && errors.push('Must be valid e-mail');
				!this.$v.regForm.email.required && errors.push('E-mail is required');
				return errors;
			},

			passwordErrors() {
				const errors = [];
				if (!this.$v.regForm.password.$dirty) return errors;
				!this.$v.regForm.password.required && errors.push('Password is required');
				return errors;
			},

			firstNameErrors() {
				const errors = []
				if (!this.$v.regForm.first_name.$dirty) return errors
				!this.$v.regForm.first_name.required && errors.push('Name is required.')
				return errors
			},

			lastNameErrors() {
				const errors = []
				if (!this.$v.regForm.last_name.$dirty) return errors
				!this.$v.regForm.last_name.required && errors.push('Name is required.')
				return errors
			},
		},

		created() {

			if (this.$route.params.token) {

				this.isCreateAccount = false;
				this.token = this.$route.params.token;

				this.getTokenInvitation(this.token);
			}
		},
	};
</script>

<style lang="scss" scoped>

	.logo-image {
		margin-bottom: 2.3%;
	}

	.single-trainer-checkbox {
		color: #FFFFFF;
	}

	.card-container {
		border-radius: 20px;
		background-color: #A156A0;
		margin: 20px;
		color: white;


		.card-top {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			font-size: 1.7rem;
			height: 30px;
			font-weight: bold;
			background-color: #783F77;
			border-radius: 20px;
		}

		.card-center {
			height: 50px;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			font-size: 1.6rem;
			margin-top: 10px;
			font-weight: 700;
		}

		.card-bottom {
			height: 120px;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			text-align: left;
			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 5px;
			font-size: 1.3rem;
		}
	}

</style>
