<template>
	<ProjectTable/>
</template>

<script>
	import ProjectTable from "../components/Project/ProjectTable";

	export default {
		name: 'Projects',

		components: {
			ProjectTable
		},

	}
</script>
