<template>
	<v-dialog v-model="dialogDelete" max-width="500px">
		<v-card class="px-4">

			<v-card-subtitle class="py-10 text--primary text-justify">
				This will delete the project, all the
				users, projects, jobs, history, and all data for this project. This cannot be undone.
				To confirm you wish to delete all data related to this project, enter the project
				name in the box below, and click CONFIRM.
			</v-card-subtitle>

			<v-card-text>
				<v-text-field
					dense
					v-model="editedItem.name"
					label="Project name"
					outlined
				>
				</v-text-field>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					id="cancel-button"
					color="red darken-1"
					text
					@click.native="closeDelete"
				>
					Cancel
				</v-btn>

				<v-btn
					id="confirm-delete-button"
					color="green darken-1"
					text
					@click.native="deleteItemConfirm"
				>
					CONFIRM
				</v-btn>
				<v-spacer></v-spacer>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		name: "ProjectDeleteDialog",

		props: {

			value: {
				Boolean,
				default: false
			},

			editedItemProp: {
				type: Object,
				required: true,
				default: {
					name: '',
				}
			},
		},

		data() {
			return {
				editedItem: this.editedItemProp,
			}
		},

		methods: {

			deleteItemConfirm() {

				//TO DO

				if (this.editedItemProp.name === this.editedItem.name) {

					this.$emit('deleteItemConfirm', this.editedItem);
					this.$store.dispatch('snackbar/showSnackbarMessage', {
						message: 'Organization was deleted successfully',
						duration: 4000,
						mode: 'success'
					});
					this.closeDelete();
					return;
				}

				this.$store.dispatch('snackbar/showSnackbarMessage', {
					message: 'To delete project names must mach',
					duration: 6000,
					mode: 'fail'
				});

			},

			closeDelete() {

				this.dialogDelete = false;
			},
		},

		computed: {

			dialogDelete: {

				get() {
					return this.value
				},

				set(value) {
					this.$emit('input', value)
				}
			},

		},
	}
</script>

<style scoped>

</style>