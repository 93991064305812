<template>
	<v-card
		@drop.prevent="onDrop($event)"
		@dragover.prevent="dragover = true"
		@dragenter.prevent="dragover = true"
		@dragleave.prevent="dragover = false"
		:class="{ 'grey lighten-2': dragover }"
		style="border: dashed lightgray; box-shadow: none"
	>
		<v-card-subtitle class="text-center">
			Scan the tag with your scanner
		</v-card-subtitle>
	</v-card>
</template>

<script>
	export default {
		name: "UploadTag",
		props: {
			dialog: {
				type: Boolean,
				required: true
			},
			multiple: {
				type: Boolean,
				default: false
			}
		},

		data() {
			return {
				dragover: false,
				uploadedFiles: []
			};
		},

		methods: {
			closeDialog() {
				// Remove all the uploaded files
				this.uploadedFiles = [];
				// Close the dialog box
				this.$emit("update:dialog", false);
			},
			removeFile(fileName) {
				// Find the index of the
				const index = this.uploadedFiles.findIndex(
					file => file.name === fileName
				);
				// If file is in uploaded files remove it
				if (index > -1) this.uploadedFiles.splice(index, 1);
			},
			onDrop(e) {
				this.dragover = false;
				// If there are already uploaded files remove them
				if (this.uploadedFiles.length > 0) this.uploadedFiles = [];
				// If user has uploaded multiple files but the component is not multiple throw error
				if (!this.multiple && e.dataTransfer.files.length > 1) {
					this.$store.dispatch("addNotification", {
						message: "Only one file can be uploaded at a time..",
						colour: "error"
					});
				}
				// Add each file to the array of uploaded files
				else
					e.dataTransfer.files.forEach(element =>
						this.uploadedFiles.push(element)
					);
			},
			submit() {
				// If there aren't any files to be uploaded throw error
				if (!this.uploadedFiles.length > 0) {
					this.$store.dispatch("addNotification", {
						message: "There are no files to upload",
						colour: "error"
					});
				} else {
					// Send uploaded files to parent component
					this.$emit("filesUploaded", this.uploadedFiles);
					// Close the dialog box
					this.closeDialog();
				}
			}
		}
	};
</script>