<template>
	<v-dialog v-model="dialogDelete" max-width="800px" @click:outside="closeDialog">
		<v-card class="py-4">

			<v-card-title class="py-7 d-flex justify-center">
				<h3> {{ formTitle }} </h3>
			</v-card-title>

			<v-card-text>
				<v-container>
					<v-row>
						<v-col
							cols="12"
							class="py-1"
						>
							<v-divider></v-divider>

							<h2> Payment </h2>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="4"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="cardItem.name"
								required
								:error-messages="nameError"
								@blur="$v.cardItem.name.$touch()"
								label="Name"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="8"
							class="py-0"

						>

							<div class="stripe-card" style="border: 1px solid #aaaaaa">
								<StripeElements
									:stripe-key="config.stripe_api_key"
									#default="{ elements }"
									ref="elms"
								>
									<StripeElement
										type="card"
										:elements="elements"
										:element-style='stripeOptions'
										:options="stripeOptions"
										ref="card"
									/>
								</StripeElements>
							</div>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="cardItem.street"

								required
								:error-messages="streetError"
								@blur="$v.cardItem.street.$touch()"
								label="Street"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="cardItem.suite"

								required
								:error-messages="suiteError"
								@blur="$v.cardItem.suite.$touch()"
								label="Suite"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="4"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="cardItem.city"

								required
								:error-messages="cityError"
								@blur="$v.cardItem.city.$touch()"
								label="City"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="4"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="cardItem.state"

								required
								:error-messages="stateError"
								@blur="$v.cardItem.state.$touch()"
								label="State"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="4"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="cardItem.zip"

								required
								:error-messages="zipError"
								@blur="$v.cardItem.zip.$touch()"
								label="Zip"
								type="number"
								outlined
							>
							</v-text-field>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					id="cancel-button"
					color="red darken-1"
					text
					@click.native="closeDialog"
				>
					close
				</v-btn>

				<v-btn
					id="create-edit-button"
					color="green darken-1"
					style="color:white"
					@click.native="save"
					:disabled="$v.$anyError"
					text
				>
					Save
				</v-btn>

				<v-spacer></v-spacer>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import {validationMixin} from "vuelidate";
	import {email, minValue, required} from "vuelidate/lib/validators";
	import DragAndDropUploadFile from "../Base/DragAndDropUploadFile";
	import config from '@/config.json'
	import {StripeElements, StripeElement} from 'vue-stripe-elements-plus'

	export default {
		name: "ProfileAddCardDialog",

		components: {
			DragAndDropUploadFile,
			StripeElements,
			StripeElement
		},

		props: {

			value: {
				Boolean,
				default: false
			},

			cardItemProp: {
				Object,
				default: {
					name: {required},
					street: {required},
					suite: {required},
					city: {required},
					state: {required},
					zip: {required},
				}

			},

			cardItemIndex: {
				Number,
				default: -1

			}
		},

		mixins: [validationMixin],

		validations: {

			cardItem: {
				name: {required},
				street: {required},
				suite: {required},
				city: {required},
				state: {required},
				zip: {required},
			}
		},

		data() {
			return {
				complete: false,
				config,

				stripeOptions: {
					hidePostalCode: true,
					style: {
						base: {
							color: '#32325d',
							lineHeight: '40px',
							fontFamily: '"Raleway", Helvetica, sans-serif',
							fontSmoothing: 'antialiased',
							fontSize: '16px',
							'::placeholder': {
								color: '#aab7c4'
							}
						},
						invalid: {
							color: '#fa755a',
							iconColor: '#fa755a'
						}
					}
				},

				cardItem: this.cardItemProp
			}
		},

		methods: {

			save() {

				this.$v.$touch();

				if (this.$v.$anyError) {

					this.$store.dispatch('snackbar/showSnackbarMessage', {
						message: 'Please enter valid data',
						duration: 6000,
						mode: 'fail'
					});
					return
				}

				this.$emit('save', this.cardItem);
				this.closeDialog();
			},

			closeDialog() {

				this.$emit('resetEditedItem');
				this.dialogDelete = false;
			},

			processUpload() {

			},

		},

		computed: {

			formTitle() {
				return this.cardItemIndex === -1 ? 'Create new card' : 'Edit card';
			},

			dialogDelete: {

				get() {
					return this.value
				},

				set(value) {
					this.$emit('input', value)
				}
			},

			nameError() {

				const errors = []
				if (!this.$v.cardItem.name.$dirty) {
					return errors
				}
				!this.$v.cardItem.name.required && errors.push('Street is required.')
				return errors
			},

			streetError() {

				const errors = []
				if (!this.$v.cardItem.street.$dirty) {
					return errors
				}
				!this.$v.cardItem.street.required && errors.push('Street is required.')
				return errors
			},

			suiteError() {

				const errors = []
				if (!this.$v.cardItem.suite.$dirty) {
					return errors
				}
				!this.$v.cardItem.suite.required && errors.push('Suite is required.')
				return errors
			},

			cityError() {

				const errors = []
				if (!this.$v.cardItem.city.$dirty) {
					return errors
				}
				!this.$v.cardItem.city.required && errors.push('City is required.')
				return errors
			},

			stateError() {

				const errors = []
				if (!this.$v.cardItem.state.$dirty) {
					return errors
				}
				!this.$v.cardItem.state.required && errors.push('State is required.')
				return errors
			},

			zipError() {

				const errors = []
				if (!this.$v.cardItem.zip.$dirty) {
					return errors
				}
				!this.$v.cardItem.zip.required && errors.push('Zip code is required.')
				return errors
			},
		},
	}
</script>

<style scoped>

</style>