<template>
	<v-dialog
		v-model="dialog"
		max-width="1100px"
		@click:outside=close
	>

		<template v-slot:activator="{ on, attrs }">
			<v-btn
				color="primary"
				dark
				class="mb-2 px-8"
				:color="'green'"
				v-bind="attrs"
				v-on="on"
			>
				New
			</v-btn>
		</template>

		<v-card>
			<v-card-title class="d-flex justify-center">
				<span id="dialog-title" class="text-h5 text-center">{{ formTitle }}</span>
			</v-card-title>

			<v-card-text>
				<v-container>
					<v-row>
						<v-col
							cols="12"
							sm="12"
							md="12"
							class="py-0"
						>
							<v-text-field
								v-model="editedItem.name"
								dense
								label="Project Description"
								outlined
								required
								:error-messages="organizationNameError"
								@blur="$v.editedItem.name.$touch()"
							>
							</v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col
							cols="12"
							class="py-1"
						>
							<v-divider></v-divider>

							<h2> Primary contact information </h2>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-menu
								v-model="menuOriginDate"
								:close-on-content-click="false"
								:nudge-right="40"
								transition="scale-transition"
								offset-y
								min-width="auto"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="editedItem.originDate"
										label="Origination Date"
										prepend-icon="mdi-calendar"
										readonly
										:error-messages="originDateError"
										v-bind="attrs"
										v-on="on"
									></v-text-field>
								</template>
								<v-date-picker
									v-model="editedItem.originDate"
									@input="menuOriginDate = false"

								></v-date-picker>
							</v-menu>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-menu
								v-model="menuTargetDate"
								:close-on-content-click="false"
								:nudge-right="40"
								transition="scale-transition"
								offset-y
								min-width="auto"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="editedItem.targetDate"
										label="Completion Target"
										prepend-icon="mdi-calendar"
										readonly
										:error-messages="targetDateError"
										v-bind="attrs"
										v-on="on"
									></v-text-field>
								</template>
								<v-date-picker
									v-model="editedItem.targetDate"
									@input="menuTargetDate = false"

								></v-date-picker>
							</v-menu>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.totalAmount"
								required
								:error-messages="totalAmountError"
								@blur="$v.editedItem.totalAmount.$touch()"
								label="Contract Amount"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.primary_contact"
								required
								:error-messages="primaryContactError"
								@blur="$v.editedItem.primary_contact.$touch()"
								label="Primary Contact"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.project_manager"
								required
								:error-messages="projectManagerError"
								@blur="$v.editedItem.project_manager.$touch()"
								label="Project Manager"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.email"
								required
								:error-messages="emailError"
								@blur="$v.editedItem.email.$touch()"
								label="Contact Email"
								outlined
							>
							</v-text-field>
						</v-col>
					</v-row>

					<v-row>

						<v-col
							cols="12"
							class="py-1"
						>
							<v-divider></v-divider>

							<h2> Project location </h2>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.street"
								required
								:error-messages="streetError"
								@blur="$v.editedItem.street.$touch()"
								label="Street"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.suite"
								required
								:error-messages="suiteError"
								@blur="$v.editedItem.suite.$touch()"
								label="Suite"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="4"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.city"
								required
								:error-messages="cityError"
								@blur="$v.editedItem.city.$touch()"
								label="City"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="4"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.state"
								required
								:error-messages="stateError"
								@blur="$v.editedItem.state.$touch()"
								label="State"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="4"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.zip"
								required
								:error-messages="zipError"
								@blur="$v.editedItem.zip.$touch()"
								label="Zip"
								type="number"
								outlined
							>
							</v-text-field>
						</v-col>
					</v-row>

					<v-row>

						<v-col
							cols="12"
							class="py-1"
						>
							<v-divider></v-divider>

							<h2> Trades </h2>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.trade"
								label="Add trade"
								outlined
							>
							</v-text-field>
						</v-col>

					</v-row>
				</v-container>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					id="cancel-button"
					color="red darken-1"
					style="color:white"
					@click.native="close"
					text
				>
					Cancel
				</v-btn>

				<v-btn
					id="create-edit-button"
					color="green darken-1"
					style="color:white"
					@click.native="save"
					:disabled="$v.$anyError"
					text
				>
					{{ buttonText }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import {validationMixin} from 'vuelidate'
	import {required, email, minValue, requiredIf} from 'vuelidate/lib/validators'
	import TitlesService from "@/services/titles.service";
	import config from '@/config.json'
	// import numeric from "vuelidate/lib/validators/numeric";

	export default {
		name: "ProjectCreateEditDialog",

		components: {},

		props: {

			value: {
				Boolean,
				default: false
			},

			editedItemProp: {
				type: Object,
				required: true,
				default: {
					name: '',
					originDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
					targetDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
					totalAmount: null,
					primary_contact: '',
					project_manager: '',
					email: '',
					street: '',
					suite: '',
					city: '',
					state: '',
					zip: '',
					trade: ''
				}
			},

			editedIndexProp: {
				type: Number,
				required: true,
				default: false
			},

		},

		mixins: [validationMixin],

		validations: {

			editedItem: {
				name: {required},
				originDate: {required},
				targetDate: {required},
				totalAmount: {required},
				primary_contact: {required},
				project_manager: {required},
				email: {required, email},
				street: {required},
				suite: {required},
				city: {required},
				state: {required},
				zip: {required, minValue: minValue(1)},
			}
		},

		data() {
			return {
				complete: false,
				config,
				menuOriginDate: false,
				menuTargetDate: false,

				editedItem: this.editedItemProp,
				editedIndex: this.editedIndexProp,
				titles: [],

				defaultItem: {
					name: '',
					originDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
					targetDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
					totalAmount: null,
					primary_contact: '',
					project_manager: '',
					email: '',
					street: '',
					suite: '',
					city: '',
					state: '',
					zip: '',
					trade: ''
				},
			}
		},

		methods: {

			async getAllTitles() {

				this.titles = await TitlesService.getTitles();
			},

			close() {

				this.dialog = false;
				// this.$emit('close');
				this.$emit('resetEditedItem', this.defaultItem);
				this.editedIndex = -1;
				this.editedItem = Object.assign({}, this.defaultItem);
				this.$v.$reset();
			},

			save() {

				this.$v.$touch();

				if (this.$v.$anyError) {

					this.$store.dispatch('snackbar/showSnackbarMessage', {
						message: 'Please enter valid data',
						duration: 6000,
						mode: 'fail'
					});
					return
				}

				this.$emit('save', this.editedItem);
				this.close();
			},
		},

		computed: {

			formTitle() {
				return this.editedIndex === -1 ? 'Create new project' : 'Edit project';
			},

			buttonText() {
				return this.editedIndex === -1 ? 'CREATE PROJECT' : 'EDIT PROJECT';
			},

			dialog: {

				get() {
					return this.value
				},

				set(value) {
					this.$emit('input', value)
				}
			},

			organizationNameError() {

				const errors = []
				if (!this.$v.editedItem.name.$dirty) {
					return errors
				}
				!this.$v.editedItem.name.required && errors.push('Organization name is required.')
				return errors
			},

			originDateError() {

				const errors = []
				if (!this.$v.editedItem.originDate.$dirty) {
					return errors
				}
				!this.$v.editedItem.originDate.required && errors.push('Origin date is required.')
				return errors
			},

			targetDateError() {

				const errors = []
				if (!this.$v.editedItem.targetDate.$dirty) {
					return errors
				}
				!this.$v.editedItem.targetDate.required && errors.push('Target date is required.')
				return errors
			},

			primaryContactError() {

				const errors = []
				if (!this.$v.editedItem.primary_contact.$dirty) {
					return errors
				}
				!this.$v.editedItem.primary_contact.required && errors.push('Primary contact is required.')
				return errors
			},

			projectManagerError() {

				const errors = []
				if (!this.$v.editedItem.project_manager.$dirty) {
					return errors
				}
				!this.$v.editedItem.project_manager.required && errors.push('Project Manager is required.')
				return errors
			},

			totalAmountError() {

				const errors = []
				if (!this.$v.editedItem.totalAmount.$dirty) {
					return errors
				}
				!this.$v.editedItem.totalAmount.required && errors.push('Amount is required.')
				return errors
			},

			emailError() {

				const errors = []
				if (!this.$v.editedItem.email.$dirty) {
					return errors
				}
				!this.$v.editedItem.email.email && errors.push('Must be valid e-mail')
				!this.$v.editedItem.email.required && errors.push('Email is required.')
				return errors
			},

			streetError() {

				const errors = []
				if (!this.$v.editedItem.street.$dirty) {
					return errors
				}
				!this.$v.editedItem.street.required && errors.push('Street is required.')
				return errors
			},

			suiteError() {

				const errors = []
				if (!this.$v.editedItem.suite.$dirty) {
					return errors
				}
				!this.$v.editedItem.suite.required && errors.push('Suite is required.')
				return errors
			},

			cityError() {

				const errors = []
				if (!this.$v.editedItem.city.$dirty) {
					return errors
				}
				!this.$v.editedItem.city.required && errors.push('City is required.')
				return errors
			},

			stateError() {

				const errors = []
				if (!this.$v.editedItem.state.$dirty) {
					return errors
				}
				!this.$v.editedItem.state.required && errors.push('State is required.')
				return errors
			},

			zipError() {

				const errors = []
				if (!this.$v.editedItem.zip.$dirty) {
					return errors
				}
				!this.$v.editedItem.zip.required && errors.push('Zip code is required.')
				!this.$v.editedItem.zip.minValue && errors.push('Enter correct zip')
				return errors
			},
		},

		created() {
		}
	}
</script>

<style lang="scss" scoped>

	.v-label {
		font-size: 14px !important;
	}

	.stripe-card {
		width: 100%;
		height: 45px;
		border-radius: 4px;
	}
</style>