import $axios from '../plugins/axios'

class TitlesService {

	getTitles() {

		return $axios.get('/titles')
			.then((res) => {

				return res.data;
			})
	}
}

export default new TitlesService();