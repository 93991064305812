<template>
	<TagTable/>
</template>

<script>
	import TagTable from "../components/Tag/TagTable";

	export default {
		name: 'Tags',

		components: {
			TagTable,
		},

	}
</script>
