<template>
	<div>
		<div id="top-profile-page-info" class="my-10">
			<v-row>

				<v-col
					cols="12"
					sm="12"
					md="12"
				>

					<v-hover v-slot="{ hover }">
						<v-card
							class="mx-auto"
							height="200"
							max-height="250"
							:elevation="hover ? 10 : 2"
							:class="{ 'on-hover': hover }"
							outlined

						>
							<v-list-item>
								<v-icon
									size="130"
									:color="hover ? 'gray' : 'black'"
								>
									mdi-account-circle-outline
								</v-icon>

								<v-list-item-content>
									<v-list-item-title>
										<h4> John Smith </h4> <br>
									</v-list-item-title>

									<v-list-item-subtitle>
										<a href="mailto:john.b.smith@org.org">
											john.b.smith@org.org
										</a>
									</v-list-item-subtitle>
								</v-list-item-content>

							</v-list-item>

							<v-card-actions>
								<v-spacer></v-spacer>

								<div class="text-center">
									<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												v-bind="attrs"
												v-on="on"
											>
												Actions
												<v-icon
													right
													dark
													large
												>
													mdi-menu-down
												</v-icon>
											</v-btn>
										</template>
										<v-list>
											<v-list-item
												v-for="(item, index) in items"
												:key="index + '-profile'"
												@click.native="openAddCardModal"
												link
											>
												<v-list-item-title>{{ item.title }}</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</div>

							</v-card-actions>
						</v-card>
					</v-hover>
				</v-col>

			</v-row>
		</div>

		<div class="cards my-9">
			<v-row>
				<v-col
					v-for="(card, index) in cards"
					:key="index + '-card'"
					:cols="12"
					lg="4"
					md="12"
					sm="12"
					xs="12"
				>
					<v-hover v-slot="{ hover }">
						<v-card
							class="mx-auto"
							max-width="500"
							height="200"
							max-height="250"
							:elevation="hover ? 10 : 2"
							:class="{ 'on-hover': hover }"
							outlined
						>
							<v-list-item three-line>
								<v-list-item-content>
									<v-list-item-title class="text-h5 mb-1 font-weight-bold">
										Payment Methods
									</v-list-item-title>
									<v-spacer></v-spacer>

									<v-list-item-subtitle>

										<p>
											<span> {{ card.name }} </span> <br>
											<span> 4*** **** **** 1111 </span> <br>
										</p>
									</v-list-item-subtitle>
								</v-list-item-content>

								<v-icon
									size="120"
									class="pt-5 mt-2"
									:color="hover ? 'gray' : 'black'"
								>
									mdi-currency-usd
								</v-icon>
							</v-list-item>

							<v-divider class="pa-0"></v-divider>

							<v-card-actions>
								<v-btn
									text
									color="#1867C0"
									small
								>
									<u> Manage payment method</u>
								</v-btn>

								<v-spacer></v-spacer>

								<div class="text-center">
									<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												v-bind="attrs"
												v-on="on"
												small
											>
												Actions
												<v-icon
													right
													dark
													large
												>
													mdi-menu-down
												</v-icon>

											</v-btn>
										</template>
										<v-list>
											<v-list-item
												v-for="(item, indexAction) in cardActionItems"
												:key="index + indexAction +'-card'"
												@click.native="cardAction(indexAction, card)"
												link
											>
												<v-list-item-title>{{ item.title }}</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</div>

							</v-card-actions>
						</v-card>
					</v-hover>
				</v-col>
			</v-row>
		</div>

		<OrganizationTableForeignMemberships/>

		<ProfileAddCardDialog
			v-model="cardModalDialog"
			:key="cardModalKey + '-card'"
			@save="saveCard"
			:card-item-prop="editedItem"
			:card-item-index="editedIndex"
			@resetEditedItem="resetEditedItem"
		/>

		<ProfileCardDeleteDialog
			v-model="cardModalDeleteDialog"
			:key="cardModalDeleteKey + '-delete-card'"
			:edited-item-prop.sync="editedItem"
			@deleteItemConfirm="deleteItemConfirm"
		/>
	</div>
</template>

<script>

	import OrganizationTableForeignMemberships from "@/components/Organization/OrganizationTableForeignMemberships";
	import ProfileAddCardDialog from "../components/Profile/ProfileAddCardDialog";
	import ProfileCardDeleteDialog from "../components/Profile/ProfileCardDeleteDialog";

	export default {
		name: 'UserProfile',

		components: {
			ProfileCardDeleteDialog,
			ProfileAddCardDialog,
			OrganizationTableForeignMemberships
		},

		data: () => ({
			billingStatus: true,
			items: [
				{title: 'Add card'},
			],
			cardModalDialog: false,
			cardModalKey: 0,

			cards: [
				{
					city: "Atlanta",
					name: "Mihail Mihalev",
					state: "GA11",
					street: "Str 4444",
					stripe_token: Object,
					suite: "Ap. 2",
					zip: "11111",
				},
				{
					city: "Atlanta",
					name: "Mihail Mihalev",
					state: "GA11",
					street: "Str 4444",
					stripe_token: Object,
					suite: "Ap. 2",
					zip: "11111",
				}
			],
			cardActionItems: [
				{title: 'Edit'},
				{title: 'Delete'},
			],

			cardEdit: {},
			editedItem: {
				city: "",
				name: "",
				state: "",
				street: "",
				stripe_token: {},
				suite: "",
				zip: "",
			},

			defaultItem: {
				city: "",
				name: "",
				state: "",
				street: "",
				stripe_token: {},
				suite: "",
				zip: "",
			},
			editedIndex: -1,

			cardModalDeleteDialog: false,
			cardModalDeleteKey: 0,
		}),

		methods: {

			openAddCardModal() {

				this.editedIndex = -1;
				this.cardModalDialog = true;
				this.cardModalKey++;
			},

			saveCard(card) {

				this.cards.push(card);
			},

			editItem(item) {

				this.editedIndex = this.cards.indexOf(item);
				this.cardModalKey++;
				this.editedItem = Object.assign({}, item);
				this.cardModalDialog = true;
			},

			openDeleteItemDialog(item) {

				this.editedIndex = this.cards.indexOf(item);
				this.cardModalDeleteKey++;
				this.editedItem = Object.assign({}, item);
				this.cardModalDeleteDialog = true;
			},

			cardAction(index, card) {

				if (index === 0) {

					// this.cardEdit = this.cards[cardId];
					this.editItem(card);
					return;
				}

				this.openDeleteItemDialog();

			},

			closeDelete() {

				this.dialogDelete = false;

				this.$nextTick(() => {
					this.cardEdit = Object.assign({}, this.defaultItem);
					this.editedIndex = -1;
				})
			},

			deleteItemConfirm(cardItem) {

				this.cards.splice(this.cardItem, 1);
				this.closeDelete();
			},

			resetEditedItem(defaultItem) {
				this.editedItem = Object.assign({}, defaultItem);
			},
		}
	}
</script>

<style lang="scss" scoped>

	.v-list-item--three-line .v-list-item__subtitle {
		white-space: initial;
		-webkit-line-clamp: 4 !important;
	}
</style>