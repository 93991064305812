<template>
	<v-dialog v-model="dialogDelete" max-width="1200px">
		<v-card class="py-4">

			<v-card-title class="py-7 d-flex justify-center">
				<h3> Add equipment to this project</h3>
			</v-card-title>

			<v-card-text>
				<v-row>
					<v-col
						cols="12"
						sm="12"
						md="12"
						class="py-0"
					>
						<EquipmentTable
							:is-dialog-mode="true"
							@save="save"
						/>
					</v-col>

				</v-row>
			</v-card-text>

		</v-card>
	</v-dialog>
</template>

<script>
	import {validationMixin} from "vuelidate";
	import {email, minValue, required} from "vuelidate/lib/validators";
	import DragAndDropUploadFile from "../Base/DragAndDropUploadFile";
	import EquipmentTable from "../Equipment/EquipmentTable";

	export default {
		name: "ProjectAddEquipmentDialog",
		components: {EquipmentTable, DragAndDropUploadFile},
		props: {

			value: {
				Boolean,
				default: false
			},
		},

		mixins: [validationMixin],

		validations: {

			editedItem: {
				type: {required},
				carrier: {required},
				valid_from_date: {required},
				expires_date: {required},
				notes: {required},
			}
		},

		data() {
			return {

				dialog: false,

				defaultItem: {
					name: "",
					location: "",
					responsible: "",
					email: "",
					phone: "",
					icon: "",
				},
				editedIndex: -1,

				editedItem: {
					name: "",
					location: "",
					responsible: "",
					email: "",
					phone: "",
					icon: "",
				},
				search: '',
				dialogKeyCreateEditDialog: 0,
			}
		},

		methods: {

			save(item) {

				this.$store.dispatch('snackbar/showSnackbarMessage', {
					message: 'Equipment added',
					duration: 4000,
					mode: 'success'
				});

				this.$emit('save', item);
				this.closeDialog();
			},

			closeDialog() {

				this.dialogDelete = false;
			},

			processUpload() {

			}
		},

		computed: {

			dialogDelete: {

				get() {
					return this.value
				},

				set(value) {
					this.$emit('input', value)
				}
			},

		},

		created() {
		},
	}
</script>

<style scoped>

</style>