<template>
	<v-row
		class="d-flex justify-center align-center mb-6 auth-container"
		id="login-main"
		style="background: linear-gradient(-135deg,#ffffff,#dcdcdc);"
	>
		<v-col
			cols="12"
			sm="10"
			md="8"
			lg="6"
		>

			<v-card class="py-10">
				<v-card-title class="d-flex justify-center">
					<h4 class="text-h4">United CS Compliance</h4> <br>

				</v-card-title>

				<v-card-subtitle class="d-flex justify-center">
					<span> Sign in</span>
				</v-card-subtitle>

				<v-card-text>
					<v-container>
						<v-row class="d-flex justify-center">

							<v-col
								cols="12"
								sm="12"
								md="10"
							>
								<v-text-field
									v-model="loginForm.email"
									:error-messages="emailErrors"
									label="E-mail"
									required
									@blur="$v.loginForm.email.$touch()"
								/>
							</v-col>
						</v-row>

						<v-row class="d-flex justify-center">
							<v-col
								cols="12"
								sm="12"
								md="10"
							>
								<v-text-field
									v-model="loginForm.password"
									type="password"
									:error-messages="passwordErrors"
									label="Password"
									required
									@blur="$v.loginForm.password.$touch()"
								/>
							</v-col>
						</v-row>

						<v-row class="d-flex flex-column align-center justify-center">
							<a
								@click.stop="openResetPasswordDialog()"
								id="forgot-password"
							>
								Forgot password?
							</a>

							<a
								@click.stop="goToRegister"
								id="register-button"
							>
								Register
							</a>

						</v-row>
					</v-container>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						class="mr-4 px-10"
						style="color: white"
						id="login-button"
						color="success"
						:disabled="$v.$error"
						@click.native="login"
						:loading="buttonLoadingSpinner"
					>
						LOGIN
					</v-btn>
				</v-card-actions>
			</v-card>

			<AuthResetPasswordDialog
				:key="dialogKey"
				:showDialog.sync="showResetPassRequestDialog"/>
		</v-col>
	</v-row>
</template>

<script>
	import AuthResetPasswordDialog from '@/components/Auth/AuthResetPasswordDialog';
	import {validationMixin} from 'vuelidate'
	import {required, email} from 'vuelidate/lib/validators'

	export default {
		name: 'AuthLogin',

		mixins: [validationMixin],

		components: {
			AuthResetPasswordDialog,
		},

		validations: {
			loginForm: {
				email: {required, email},
				password: {required},
			}
		},

		data() {

			return {

				loginForm: {
					email: '',
					password: '',
				},
				dialogKey: 0,
				showResetPassRequestDialog: false,
				buttonLoadingSpinner: false,
			}
		},

		methods: {

			login() {

				let _this = this;
				this.$v.$touch();
				this.buttonLoadingSpinner = true;

				this.$store.dispatch('auth/login', this.loginForm)
					.then((res) => {

						//Set axios Authorization header
						this.$axios.defaults.headers.common['Authorization'] = `Bearer ${res.auth_token}`;
						this.$router.push('/dashboard');
						this.buttonLoadingSpinner = false;
					})
					.catch((error) => {

						_this.$store.dispatch('snackbar/showSnackbarMessage', {
							message: 'Wrong username or password',
							duration: 6000,
							mode: 'fail'
						});
						this.buttonLoadingSpinner = false;
					});
			},

			openResetPasswordDialog() {

				this.dialogKey++;
				this.showResetPassRequestDialog = true;
			},

			goToRegister() {
				this.$router.replace('/register');
			},
		},

		computed: {

			emailErrors() {

				const errors = [];
				if (!this.$v.loginForm.email.$dirty) return errors;
				!this.$v.loginForm.email.email && errors.push('Must be valid e-mail');
				!this.$v.loginForm.email.required && errors.push('E-mail is required');
				return errors;
			},

			passwordErrors() {
				const errors = [];
				if (!this.$v.loginForm.password.$dirty) return errors;
				!this.$v.loginForm.password.required && errors.push('Password is required');
				return errors;
			},
		},
	};
</script>

<style lang="scss" scoped>

	.guest-login {
		text-align: right;
	}

	.forgot-pass {
		text-align: left;
	}

	.auth-form__link {
		color: white;
		cursor: pointer;
	}

	.auth-form__link--forgot-pass-link:hover, .auth-form__link--log-as-guest:hover {
		color: #A156A0;
	}
</style>