<template>
	<v-data-table
		:headers="headers"
		:items="organizations"
		sort-by="email"
		class="elevation-2"
	>
		<template v-slot:item.name="{ item }">
			{{ item.name }}
		</template>

		<template v-slot:item.role="{ item }">

			<div>
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							v-bind="attrs"
							v-on="on"
							small
						>
							Role
							<v-icon
								right
								dark
								dense
							>
								mdi-menu-down
							</v-icon>

						</v-btn>
					</template>
					<v-list>
						<v-list-item
							link
							v-for="(role, index) in roles"
							:key="index"
							@click.stop=""
						>
							<v-list-item-title>{{ role.title }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>

		</template>

		<template v-slot:top>
			<v-toolbar
				flat
			>
				<v-toolbar-title>Foreign organization</v-toolbar-title>
				<v-autocomplete
					v-model="select"
					:loading="loading"
					:items="items"
					:search-input.sync="search"
					cache-items
					class="mx-4 gray"
					dense
					color="white"
					hide-no-data
					clearable
					hide-details
					solo-inverted
					label="Search for a foreign organization"
				/>

				<v-spacer></v-spacer>
			</v-toolbar>
		</template>

		<template v-slot:item.actions="{ item, index }">
			<v-icon
				:id="'delete-organization-button-' + index"
				small
			>
				mdi-delete
			</v-icon>
		</template>

		<template v-slot:no-data>
			<p class="my-4 ">
				No foreign organization memberships
			</p>

			<v-btn
				class="my-4"
				@click="initialize"
				small
			>
				Reload
			</v-btn>
		</template>
	</v-data-table>
</template>

<script>

	import OrganizationCreateEditDialog from "./OrganizationCreateEditDialog";
	import OrganizationDeleteDialog from "./OrganizationDeleteDialog";

	export default {
		name: 'OrganizationTableForeignMemberships',

		components: {
			OrganizationDeleteDialog,
			OrganizationCreateEditDialog
		},

		data: () => ({

			headers: [
				{text: 'Company', align: 'start', sortable: false, value: 'name'},
				{text: 'Role', value: 'role'},
				{text: '', value: 'actions', sortable: false, align: 'end'},
			],
			organizations: [],
			roles: [
				{title: 'Administrator'},
				{title: 'Project Manager'},
				{title: 'Superintendent'},
				{title: 'Worker'},
			],

			loading: false,
			items: [],
			search: null,
			select: null,
			organizationsSearchItems: [
				'Test',
				'Test organizations 1',
				'Test organizations 2',
				'Test organizations 3',

			],
		}),

		methods: {

			initialize() {

				this.organizations = [
					{
						name: 'Lorem ipsum Organization, inc.',
						role: 1,
					},
					{
						name: 'Lorem ipsum Organization, inc.',
						role: 2,
					},
					{
						name: 'Lorem ipsum Organization, inc.',
						role: 3,
					},
					{
						name: 'Lorem ipsum Organization, inc.',
						role: 4,
					},
				];
			},

			querySelections(v) {

				this.loading = true;

				setTimeout(() => {

					this.items = this.organizationsSearchItems.filter(e => {
						return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1;
					});
					this.loading = false;
				}, 500);
			},
		},

		watch: {

			search(val) {
				val && val !== this.select && this.querySelections(val);
			},
		},

		created() {
			this.initialize();
		},
	}
</script>
