<template>
	<v-data-table
		:headers="headers"
		:items="organizations"
		:search="search"
		sort-by="primary_contact_email"
		class="elevation-2"
		@click:row="goToOrganization"
	>
		<template v-slot:item.name="{ item }">
			<v-icon size="34" class="mr-2"> mdi-account-circle-outline</v-icon>
			{{ item.name }}
		</template>

		<template v-slot:item.active="{ item }">
			<span v-if="item.active" style="color: green"> Active </span>
			<span v-if="item.active !== true" style="color: red"> Inactive </span>

		</template>

		<template v-slot:top>
			<v-toolbar
				flat
			>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
				/>

				<v-spacer></v-spacer>

				<OrganizationCreateEditDialog
					:edited-item-prop.sync="editedItem"
					:key="dialogKeyCreateEditDialog"
					:edited-index-prop="editedIndex"
					v-model="dialog"
					@refreshOrganizations="getAllOrganizations"
					@resetEditedItem="resetEditedItem"
				/>

				<OrganizationDeleteDialog
					v-model="dialogDelete"
					:edited-item-prop.sync="editedItem"
				/>

			</v-toolbar>
		</template>

		<template v-slot:item.actions="{ item, index }">
			<v-icon
				:id="'edit-organization-button-' + index"
				small
				class="mr-2"
				@click.stop="editItem(item)"
			>
				mdi-pencil
			</v-icon>

			<v-icon
				:id="'delete-organization-button-' + index"
				small
				@click.stop="deleteItem(item)"
			>
				mdi-delete
			</v-icon>
		</template>
		<template v-slot:no-data>

			<v-progress-linear
				indeterminate
				color="cyan"
				class="my-9"
			></v-progress-linear>

			<v-btn
				id="reload-data-button"
				color="primary"
				class="my-9"
				@click.native="getAllOrganizations"
			>
				RELOAD
			</v-btn>
		</template>
	</v-data-table>
</template>

<script>

	import OrganizationCreateEditDialog from "./OrganizationCreateEditDialog";
	import OrganizationDeleteDialog from "./OrganizationDeleteDialog";
	import OrganizationsService from "@/services/organizations.service"

	export default {
		name: 'OrganizationTable',

		components: {
			OrganizationDeleteDialog,
			OrganizationCreateEditDialog
		},

		data: () => ({

			dialog: false,
			dialogDelete: false,
			headers: [
				{text: 'Name', align: 'start', sortable: false, value: 'name'},
				{text: 'Email', value: 'primary_contact.email'},
				{text: 'Phone', value: 'primary_contact.phone'},
				{text: 'Role', value: 'primary_contact.role_id'},
				{text: 'Active', value: 'active'},
				{text: '', value: 'actions', sortable: false, align: 'end'},
			],
			organizations: [],
			editedIndex: -1,

			editedItem: {
				name: '',

				primary_contact_first_name: '',
				primary_contact_last_name: '',
				primary_contact_title_id: '',
				primary_contact_phone_number: '',
				primary_contact_email: '',

				trial_period: false,
				trial_period_days: null,
				free_account: false,
				isBillingRequire: false,

				street: '',
				suite: '',
				city: '',
				state: '',
				zip: '',

				stripe_token: {
					id: '',
					cardNumber: '',
					expiration: '',
					cvv: '',
				}

			},
			search: '',
			dialogKeyCreateEditDialog: 0,
		}),

		methods: {

			async getAllOrganizations() {

				this.organizations = await OrganizationsService.getOrganizations();
				this.$forceUpdate();
			},

			assignOrganization(organization) {

				this.editedItem = Object.assign({}, organization);
				this.editedItem.primary_contact_first_name = organization.primary_contact.first_name;
				this.editedItem.primary_contact_last_name = organization.primary_contact.last_name;
				this.editedItem.primary_contact_title_id = organization.primary_contact.title_id;
				this.editedItem.primary_contact_phone_number = organization.primary_contact.phone;
				this.editedItem.primary_contact_email = organization.primary_contact.email;
			},

			assignOrganizationForDelete(organization) {

				this.editedItem.id = organization.id;
				this.editedItem.name = organization.name;
			},

			editItem(item) {

				this.editedIndex = this.organizations.indexOf(item);
				this.dialogKeyCreateEditDialog++;
				this.assignOrganization(item);
				this.dialog = true;
			},

			deleteItem(item) {

				this.editedIndex = this.organizations.indexOf(item);
				this.dialogKeyCreateEditDialog++;
				this.assignOrganizationForDelete(item);
				this.dialogDelete = true;
			},

			goToOrganization(data, organization) {
				this.$router.push(`/organizations/${organization.index}`);
			},

			resetEditedItem(defaultItem) {
				this.editedItem = Object.assign({}, defaultItem);
			}
		},

		created() {
			this.getAllOrganizations();
		},
	}
</script>
