<template>
	<v-dialog v-model="dialogDelete" max-width="1200px">
		<v-card class="py-4">

			<v-card-title class="py-7 d-flex justify-center">
				<h3> Add subcontractor to this project</h3>
			</v-card-title>

			<v-card-text>
				<v-row>
					<v-col
						cols="12"
						sm="12"
						md="12"
						class="py-0"
					>
						<SubcontractorTable
							:is-dialog-mode="true"
							@save="save"
						/>
					</v-col>

				</v-row>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					id="cancel-button"
					color="red darken-1"
					text
					@click.native="closeDialog"
				>
					close
				</v-btn>

				<v-btn
					id="create-edit-button"
					color="green darken-1"
					style="color:white"
					@click.native="save"
					:disabled="$v.$anyError"
					text
				>
					Save
				</v-btn>

				<v-spacer></v-spacer>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import {validationMixin} from "vuelidate";
	import {email, minValue, required} from "vuelidate/lib/validators";
	import DragAndDropUploadFile from "../Base/DragAndDropUploadFile";
	import SubcontractorTable from "../Subcontractor/SubcontractorTable";

	export default {
		name: "ProjectAddSubcontractorDialog",
		components: {SubcontractorTable, DragAndDropUploadFile},
		props: {

			value: {
				Boolean,
				default: false
			},
		},

		mixins: [validationMixin],

		validations: {

			editedItem: {
				type: {required},
				carrier: {required},
				valid_from_date: {required},
				expires_date: {required},
				notes: {required},
			}
		},

		data() {
			return {

				dialog: false,
				headers: [
					{text: 'Name', align: 'start', sortable: false, value: 'name'},
					{text: 'Contact', align: 'start', sortable: false, value: 'contact'},
					{text: 'Email', align: 'start', sortable: false, value: 'email'},
					{text: 'Phone', align: 'start', sortable: false, value: 'phone'},
					{text: 'Trade', align: 'start', sortable: false, value: 'trade'},
					{text: 'Preferred', value: 'preferred_contractor'},
					{text: 'Range', align: 'start', sortable: false, value: `service_radius`},
					{text: '', value: 'actions', sortable: false, align: 'end'},
				],
				subcontractors: [],
				defaultItem: {
					name: '',
					first_name: '',
					last_name: '',
					email: '',
					phone: '',
					street: '',
					suite: '',
					city: '',
					state: '',
					zip: '',
					service_radius: '',
					preferred_contractor: false,
					blacklisted: false,
					trade: '',
					certification: '',
					type: '',
				},
				editedIndex: -1,

				editedItem: {
					name: '',
					first_name: '',
					last_name: '',
					email: '',
					phone: '',
					street: '',
					suite: '',
					city: '',
					state: '',
					zip: '',
					service_radius: '',
					preferred_contractor: false,
					blacklisted: false,
					trade: '',
					certification: '',
					type: '',
				},
				search: '',
				dialogKeyCreateEditDialog: 0,
			}
		},

		methods: {

			initialize() {

				this.subcontractors = [
					{
						name: 'Lorem ipsum Organization, inc.',
						first_name: 'Bill',
						last_name: 'Smith',
						email: 'someone@example.org',
						phone: '770 123 5678',
						service_radius: 75,
						preferred_contractor: true,
						street: 'Street 1',
						suite: 'Suite 1',
						city: 'Los Angeles',
						state: 'LA',
						zip: 1111,
						trade: 'Electrician'
					},
					{
						name: 'Lorem ipsum Organization, inc.',
						first_name: 'Bill',
						last_name: 'Smith',
						email: 'someone@example.org',
						phone: '770 123 5678',
						service_radius: 75,
						preferred_contractor: true,
						street: 'Street 1',
						suite: 'Suite 1',
						city: 'Los Angeles',
						state: 'LA',
						zip: 1111,
						trade: 'Electrician'
					},
					{
						name: 'Lorem ipsum Organization, inc.',
						first_name: 'Bill',
						last_name: 'Smith',
						email: 'someone@example.org',
						phone: '770 123 5678',
						service_radius: 75,
						preferred_contractor: true,
						street: 'Street 1',
						suite: 'Suite 1',
						city: 'Los Angeles',
						state: 'LA',
						zip: 1111,
						trade: 'Electrician'
					},
					{
						name: 'Lorem ipsum Organization, inc.',
						first_name: 'Bill',
						last_name: 'Smith',
						email: 'someone@example.org',
						phone: '770 123 5678',
						service_radius: 75,
						preferred_contractor: true,
						street: 'Street 1',
						suite: 'Suite 1',
						city: 'Los Angeles',
						state: 'LA',
						zip: 1111,
						trade: 'Electrician'
					},
					{
						name: 'Lorem ipsum Organization, inc.',
						first_name: 'Bill',
						last_name: 'Smith',
						email: 'someone@example.org',
						phone: '770 123 5678',
						service_radius: 75,
						preferred_contractor: true,
						street: 'Street 1',
						suite: 'Suite 1',
						city: 'Los Angeles',
						state: 'LA',
						zip: 1111,
						trade: 'Electrician'
					},
					{
						name: 'Lorem ipsum Organization, inc.',
						first_name: 'Bill',
						last_name: 'Smith',
						email: 'someone@example.org',
						phone: '770 123 5678',
						service_radius: 75,
						preferred_contractor: true,
						street: 'Street 1',
						suite: 'Suite 1',
						city: 'Los Angeles',
						state: 'LA',
						zip: 1111,
						trade: 'Electrician'
					},
				];
			},

			save(item) {

				this.$store.dispatch('snackbar/showSnackbarMessage', {
					message: 'Subcontractor added',
					duration: 4000,
					mode: 'success'
				});

				this.$emit('save', item);
				this.closeDialog();
			},

			closeDialog() {

				this.dialogDelete = false;
			},

			processUpload() {

			}
		},

		computed: {

			dialogDelete: {

				get() {
					return this.value
				},

				set(value) {
					this.$emit('input', value)
				}
			},

		},

		created() {
			this.initialize();
		},
	}
</script>

<style scoped>

</style>