<template>
	<v-dialog v-model="dialogDelete" max-width="800px">
		<v-card class="py-4">

			<v-card-title class="py-7 d-flex justify-center">
				<h3> Create new equipment record</h3>
			</v-card-title>

			<v-card-text>
				<v-row>
					<v-col
						cols="12"
						sm="12"
						md="6"
						class="py-0"
					>
						<UploadPicture :dialog="true" :multiple="true" @filesUploaded="processUpload($event)"/>
					</v-col>

					<v-col
						cols="12"
						sm="12"
						md="6"
						class="py-0"
					>
						<v-text-field
							dense
							v-model="editedItem.description"
							required
							:error-messages="descriptionError"
							@blur="$v.editedItem.description.$touch()"
							label="Description"
							outlined
						>
						</v-text-field>

						<v-text-field
							dense
							v-model="editedItem.type"
							required
							:error-messages="typeError"
							@blur="$v.editedItem.type.$touch()"
							label="Type"
							outlined
						>
						</v-text-field>

						<v-text-field
							dense
							v-model="editedItem.value"
							required
							:error-messages="valueError"
							@blur="$v.editedItem.value.$touch()"
							label="Value"
							outlined
						>
						</v-text-field>

						<v-text-field
							dense
							v-model="editedItem.tag"
							required
							:error-messages="tagError"
							@blur="$v.editedItem.tag.$touch()"
							label="RFID Tag"
							outlined
						>
						</v-text-field>

						<div class="d-flex justify-end">
							<v-btn
								id="cancel-button"
								color="red darken-1"
								text
								@click.native="closeDialog"
							>
								close
							</v-btn>

							<v-btn
								id="create-edit-button"
								color="green darken-1"
								style="color:white"
								@click.native="save"
								:disabled="$v.$anyError"
								text
							>
								Create equipment record
							</v-btn>
						</div>

					</v-col>

				</v-row>
			</v-card-text>

			<v-card-actions>

			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import {validationMixin} from "vuelidate";
	import {email, minValue, required} from "vuelidate/lib/validators";
	import DragAndDropUploadFile from "../Base/DragAndDropUploadFile";
	import UploadPicture from "../Base/UploadPicture";

	export default {
		name: "EquipmentAddDialog",
		components: {UploadPicture, DragAndDropUploadFile},
		props: {

			value: {
				Boolean,
				default: false
			},
		},

		mixins: [validationMixin],

		validations: {

			editedItem: {
				description: {required},
				type: {required},
				value: {required},
				tag: {required},
			}
		},

		data() {
			return {
				menuValidDate: false,
				menuExpiresDate: false,

				editedItem: {
					description: '',
					type: '',
					value: '',
					tag: '',
					file: '',
					name: "Equipment",
					location: " - ",
					responsible: " - ",
				}
			}
		},

		methods: {

			save() {

				this.$v.$touch();

				if (this.$v.$anyError) {

					this.$store.dispatch('snackbar/showSnackbarMessage', {
						message: 'Please enter valid data',
						duration: 6000,
						mode: 'fail'
					});
					return
				}

				this.$emit('save', this.editedItem);
				this.closeDialog();
			},

			closeDialog() {

				this.dialogDelete = false;
			},

			processUpload() {

			}
		},

		computed: {

			dialogDelete: {

				get() {
					return this.value
				},

				set(value) {
					this.$emit('input', value)
				}
			},

			descriptionError() {

				const errors = []
				if (!this.$v.editedItem.description.$dirty) {
					return errors
				}
				!this.$v.editedItem.description.required && errors.push('Description is required.')
				return errors
			},

			typeError() {

				const errors = []
				if (!this.$v.editedItem.type.$dirty) {
					return errors
				}
				!this.$v.editedItem.type.required && errors.push('Type is required.')
				return errors
			},

			valueError() {

				const errors = []
				if (!this.$v.editedItem.value.$dirty) {
					return errors
				}
				!this.$v.editedItem.value.required && errors.push('Value is required.')
				return errors
			},

			tagError() {

				const errors = []
				if (!this.$v.editedItem.tag.$dirty) {
					return errors
				}
				!this.$v.editedItem.tag.required && errors.push('Tag is required.')
				return errors
			},

		},
	}
</script>

<style scoped>

</style>