<template>
	<v-dialog v-model="dialogDelete" max-width="800px">
		<v-card class="py-4">

			<v-card-title class="py-7 d-flex justify-center">
				<h3> Register new scanner </h3>
			</v-card-title>

			<v-card-text>
				<v-row class="d-flex justify-center">
					<v-col
						cols="12"
						sm="12"
						md="8"
						class="py-0"
					>
						<v-text-field
							dense
							v-model="editedItem.id"
							required
							:error-messages="idError"
							@blur="$v.editedItem.id.$touch()"
							label="Scanner ID"
							outlined
						>
						</v-text-field>

					</v-col>

				</v-row>
			</v-card-text>

			<v-card-actions class="d-flex justify-center">
				<v-btn
					id="cancel-button"
					color="red darken-1"
					text
					@click.native="closeDialog"
				>
					close
				</v-btn>

				<v-btn
					id="create-edit-button"
					color="green darken-1"
					style="color:white"
					@click.native="save"
					:disabled="$v.$anyError"
					text
				>
					Register new scanner
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import {validationMixin} from "vuelidate";
	import {email, minValue, required} from "vuelidate/lib/validators";
	import DragAndDropUploadFile from "../Base/DragAndDropUploadFile";
	import UploadPicture from "../Base/UploadPicture";

	export default {
		name: "ScannerAddDialog",
		components: {UploadPicture, DragAndDropUploadFile},
		props: {

			value: {
				Boolean,
				default: false
			},
		},

		mixins: [validationMixin],

		validations: {

			editedItem: {
				id: {required},
			}
		},

		data() {
			return {
				menuValidDate: false,
				menuExpiresDate: false,

				editedItem: {
					id: '',
					assigned: false,
					assigned_email: '',
				}
			}
		},

		methods: {

			save() {

				this.$v.$touch();

				if (this.$v.$anyError) {

					this.$store.dispatch('snackbar/showSnackbarMessage', {
						message: 'Please enter valid data',
						duration: 6000,
						mode: 'fail'
					});
					return
				}

				this.$emit('save', this.editedItem);
				this.closeDialog();
			},

			closeDialog() {

				this.dialogDelete = false;
			},

			processUpload() {

			}
		},

		computed: {

			dialogDelete: {

				get() {
					return this.value
				},

				set(value) {
					this.$emit('input', value)
				}
			},

			idError() {

				const errors = []
				if (!this.$v.editedItem.id.$dirty) {
					return errors
				}
				!this.$v.editedItem.id.required && errors.push('ID is required.')
				return errors
			},

		},
	}
</script>

<style scoped>

</style>