<template>
	<div>
		<div id="top-profile-page-info" class="my-10">
			<v-row>

				<v-col
					cols="12"
					sm="12"
					md="8"
				>

					<v-hover v-slot="{ hover }">
						<v-card
							class="mx-auto"

							height="200"
							max-height="250"
							:elevation="hover ? 10 : 2"
							:class="{ 'on-hover': hover }"
							outlined
							@click.native="$router.push(`/billing`)"
						>
							<v-list-item>

								<v-icon
									size="130"
									:color="hover ? 'gray' : 'black'"
								>
									mdi-account-circle-outline
								</v-icon>

								<v-list-item-content>
									<v-card-text>
										<h4> Lorem ipsum, inc. </h4> <br>

										<span> <b> Primary contact: </b> John Smith </span> <br>

										<span> <b>  Biling address: </b>  </span> <br>
										<span>
											1234 Happy Street
											Suite 1016
											Atlanta, GA 30332
											404-606-433
										</span>
									</v-card-text>
								</v-list-item-content>

							</v-list-item>

							<v-card-actions>
								<v-spacer></v-spacer>

								<div class="text-center">
									<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												v-bind="attrs"
												v-on="on"
											>
												Actions
												<v-icon
													right
													dark
													large
												>
													mdi-menu-down
												</v-icon>

											</v-btn>
										</template>
										<v-list>
											<v-list-item
												v-for="(item, index) in items"
												:key="index"
												@click.stop=""
												link
											>
												<v-list-item-title>{{ item.title }}</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</div>

							</v-card-actions>
						</v-card>
					</v-hover>
				</v-col>

				<v-col
					cols="12"
					sm="12"
					md="4"
				>
					<v-hover v-slot="{ hover }">
						<v-card
							class="mx-auto"
							max-width="400"
							height="200"
							max-height="250"
							:elevation="hover ? 10 : 2"
							:class="{ 'on-hover': hover }"
							outlined
							@click.native="$router.push(`/billing`)"
						>
							<v-list-item three-line>
								<v-list-item-content>
									<v-list-item-title class="text-h5 mb-1 font-weight-bold" style="color: darkgreen">
										Billing
									</v-list-item-title>
									<v-spacer></v-spacer>

									<v-list-item-subtitle class="mt-4">
											<span> Billing Status:
												<b v-if="billingStatus" style="color: green"> Active </b>
												<b v-if="billingStatus !== true" style="color: red"> Inactive </b>
											</span> <br>
										<span>  Last charge: 21/10/21 </span> <br>
										<span>  Next charge: 21/11/21</span>
									</v-list-item-subtitle>
								</v-list-item-content>

								<v-icon
									size="96"
									class="pt-5 mt-2"
									:color="hover ? 'gray' : 'black'"
								>
									mdi-currency-usd
								</v-icon>
							</v-list-item>

							<v-card-actions>
								<v-btn
									text
									color="#1867C0"
									@click.stop="$router.push(`/billing}`)"
								>
									<u> Manage billing and payments </u>
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-hover>

				</v-col>
			</v-row>
		</div>

		<OrganizationTable/>

	</div>
</template>

<script>

	import OrganizationTable from "../components/Organization/OrganizationTable";

	export default {
		name: 'OrganizationProfile',

		components: {
			OrganizationTable
		},

		data: () => ({
			billingStatus: true,
			items: [
				{title: 'Click Me'},
				{title: 'Click Me'},
				{title: 'Click Me'},
				{title: 'Click Me 2'},
			],
		}),
	}
</script>

<style lang="scss" scoped>

	.v-list-item--three-line .v-list-item__subtitle {
		white-space: initial;
		-webkit-line-clamp: 4 !important;
	}
</style>