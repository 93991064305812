<template>
	<div>
		<v-system-bar
			app
			height="72"
		>
			<v-app-bar-nav-icon class="d-md-block d-lg-none" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			<v-btn
				id="home-logo-button"
				plain
				@click.stop="$router.push(`/`)"
			>
				United CS Compliance
			</v-btn>

			<v-spacer></v-spacer>

			<h2
				id="center-logo"
				class="d-lg-block d-none"
				@click.stop="$router.push(`/`)"
			>
				United CS Compliance
			</h2>

			<v-spacer></v-spacer>

			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						id="profile-button"
						@click.stop="$router.push(`/profile`)"
						v-bind="attrs"
						v-on="on"
						icon
					>
						<v-icon size="36"> mdi-account-circle-outline</v-icon>
					</v-btn>
				</template>
				<span>My account</span>
			</v-tooltip>

			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						class="ml-5"
						id="logout-button"
						@click.stop="logout"
						v-bind="attrs"
						v-on="on"
						icon
					>
						<v-icon size="36"> mdi-exit-to-app</v-icon>
					</v-btn>
				</template>
				<span>Logout</span>
			</v-tooltip>
		</v-system-bar>

		<v-main>
			<v-navigation-drawer
				v-model="drawer"
				app
			>
				<TheLayoutWrapMenuList/>
			</v-navigation-drawer>

			<transition name="scale" mode="out-in">
				<v-container :key="key">
					<router-view></router-view>
				</v-container>
			</transition>
		</v-main>
	</div>
</template>

<script>
	import TheLayoutWrapMenuList from "./TheLayoutWrapMenuList";

	export default {
		name: "TheLayoutWrap",

		components: {
			TheLayoutWrapMenuList
		},

		data: () => ({
			drawer: null,
		}),

		methods: {

			logout() {

				this.$store.dispatch('auth/signOut').then((res) => {
					this.$router.replace('/login');
				});
			},
		},

		computed: {
			key() {
				return this.$route.path;
			}
		}
	};
</script>

<style scoped>
	@import "https://cdn.jsdelivr.net/npm/animate.css@3.5.1";
	.scale-enter-active,
	.scale-leave-active {
		transition: all 0.4s ease;
	}


	.scale-enter-from,
	.scale-leave-to {
		opacity: 0;
		transform: scale(0.9);
	}
</style>