<template>
	<v-dialog
		v-model="dialog"
		max-width="1100px"
		@click:outside=close
	>

		<template v-slot:activator="{ on, attrs }">
			<v-btn
				color="primary"
				dark
				class="mb-2 px-8"
				:color="'green'"
				v-bind="attrs"
				v-on="on"
			>
				New
			</v-btn>
		</template>

		<v-card>
			<v-card-title class="d-flex justify-center">
				<span id="dialog-title" class="text-h5 text-center">{{ formTitle }}</span>
			</v-card-title>

			<v-card-text>
				<v-container>
					<v-row>
						<v-col
							cols="12"
							sm="12"
							md="12"
							class="py-0"
						>
							<v-text-field
								v-model="editedItem.name"
								dense
								label="Contractor Name"
								outlined
								required
								:error-messages="subcontractorNameError"
								@blur="$v.editedItem.name.$touch()"
							>
							</v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col
							cols="12"
							class="py-1"
						>
							<v-divider></v-divider>

							<h2> Primary contact information </h2>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.first_name"
								required
								:error-messages="firstNameError"
								@blur="$v.editedItem.first_name.$touch()"
								label="First Name"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.last_name"
								required
								:error-messages="lastNameError"
								@blur="$v.editedItem.last_name.$touch()"
								label="Last Name"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.email"
								required
								:error-messages="emailError"
								@blur="$v.editedItem.email.$touch()"
								label="Email"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.phone"
								required
								:error-messages="phoneError"
								@blur="$v.editedItem.phone.$touch()"
								label="Phone"
								outlined
							>
							</v-text-field>
						</v-col>
					</v-row>

					<v-row>

						<v-col
							cols="12"
							class="py-1"
						>
							<v-divider></v-divider>

							<h2> Contact information </h2>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.street"
								required
								:error-messages="streetError"
								@blur="$v.editedItem.street.$touch()"
								label="Street"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.suite"
								required
								:error-messages="suiteError"
								@blur="$v.editedItem.suite.$touch()"
								label="Suite"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="4"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.city"
								required
								:error-messages="cityError"
								@blur="$v.editedItem.city.$touch()"
								label="City"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="4"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.state"
								required
								:error-messages="stateError"
								@blur="$v.editedItem.state.$touch()"
								label="State"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="4"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.zip"
								required
								:error-messages="zipError"
								@blur="$v.editedItem.zip.$touch()"
								label="Zip"
								type="number"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="4"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.service_radius"
								required
								:error-messages="serviceRadiusError"
								@blur="$v.editedItem.service_radius.$touch()"
								label="Service radius (miles)"
								type="number"
								outlined
							>
							</v-text-field>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="3"
							class="py-0"
						>
							<v-checkbox
								dense
								v-model="editedItem.preferred_contractor"
								label="Preferred Contractor"
								outlined
							>
							</v-checkbox>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="2"
							class="py-0"
						>
							<v-checkbox
								dense
								v-model="editedItem.blacklisted"
								label="Blacklisted"
								outlined
							>
							</v-checkbox>
						</v-col>
					</v-row>

					<v-row>
						<v-col
							cols="12"
							class="py-1"
						>
							<v-divider></v-divider>

							<h2> Trades </h2>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.trade"
								label="Add trade"
								outlined
							>
							</v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col
							cols="12"
							class="py-1"
						>
							<v-divider></v-divider>

							<h2> Certificates </h2>
						</v-col>

						<v-col
							cols="12"
							sm="12"
							md="6"
							class="py-0"
						>
							<v-text-field
								dense
								v-model="editedItem.certification"
								label="Add certification"
								outlined
							>
							</v-text-field>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					id="cancel-button"
					color="red darken-1"
					style="color:white"
					@click.native="close"
					text
				>
					Cancel
				</v-btn>

				<v-btn
					id="create-edit-button"
					color="green darken-1"
					style="color:white"
					@click.native="save"
					:disabled="$v.$anyError"
					text
				>
					{{ buttonText }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import {validationMixin} from 'vuelidate'
	import {required, email, minValue, requiredIf} from 'vuelidate/lib/validators'
	import TitlesService from "@/services/titles.service";
	import config from '@/config.json'
	// import numeric from "vuelidate/lib/validators/numeric";

	export default {
		name: "SubcontractorCreateEditDialog",

		components: {},

		props: {

			value: {
				Boolean,
				default: false
			},

			editedItemProp: {
				type: Object,
				required: true,
				default: {
					name: '',
					first_name: '',
					last_name: '',
					email: '',
					phone: '',
					street: '',
					suite: '',
					city: '',
					state: '',
					zip: '',
					service_radius: '',
					preferred_contractor: false,
					blacklisted: false,
					trade: '',
					certification: '',
				}
			},

			editedIndexProp: {
				type: Number,
				required: true,
				default: false
			},

		},

		mixins: [validationMixin],

		validations: {

			editedItem: {
				name: {required},
				first_name: {required},
				last_name: {required},
				email: {required, email},
				phone: {required},
				street: {required},
				suite: {required},
				city: {required},
				state: {required},
				zip: {required, minValue: minValue(1)},
				service_radius: {required},
			}
		},

		data() {
			return {
				complete: false,
				config,
				editedItem: this.editedItemProp,
				editedIndex: this.editedIndexProp,
				titles: [],

				defaultItem: {
					name: '',
					first_name: '',
					last_name: '',
					email: '',
					phone: '',
					street: '',
					suite: '',
					city: '',
					state: '',
					zip: '',
					service_radius: '',
					preferred_contractor: false,
					blacklisted: false,
					trade: '',
					certification: '',
				},
			}
		},

		methods: {

			close() {

				this.dialog = false;
				this.$emit('resetEditedItem', this.defaultItem);
				this.editedIndex = -1;
				this.editedItem = Object.assign({}, this.defaultItem);
				this.$v.$reset();
			},

			save() {

				this.$v.$touch();

				if (this.$v.$anyError) {

					this.$store.dispatch('snackbar/showSnackbarMessage', {
						message: 'Please enter valid data',
						duration: 6000,
						mode: 'fail'
					});
					return
				}

				this.$emit('save', this.editedItem);
				this.close();
			},
		},

		computed: {

			formTitle() {
				return this.editedIndex === -1 ? 'Create new subcontractor' : 'Edit subcontractor';
			},

			buttonText() {
				return this.editedIndex === -1 ? 'CREATE SUBCONTRACTOR' : 'EDIT SUBCONTRACTOR';
			},

			dialog: {

				get() {
					return this.value
				},

				set(value) {
					this.$emit('input', value)
				}
			},

			subcontractorNameError() {

				const errors = []
				if (!this.$v.editedItem.name.$dirty) {
					return errors
				}
				!this.$v.editedItem.name.required && errors.push('Subcontractor name is required.')
				return errors
			},

			firstNameError() {

				const errors = []
				if (!this.$v.editedItem.first_name.$dirty) {
					return errors
				}
				!this.$v.editedItem.first_name.required && errors.push('First name is required.')
				return errors
			},

			lastNameError() {

				const errors = []
				if (!this.$v.editedItem.last_name.$dirty) {
					return errors
				}
				!this.$v.editedItem.last_name.required && errors.push('Last name is required.')
				return errors
			},

			phoneError() {

				const errors = []
				if (!this.$v.editedItem.phone.$dirty) {
					return errors
				}
				!this.$v.editedItem.phone.required && errors.push('Phone is required.')
				return errors
			},

			emailError() {

				const errors = []
				if (!this.$v.editedItem.email.$dirty) {
					return errors
				}
				!this.$v.editedItem.email.email && errors.push('Must be valid e-mail')
				!this.$v.editedItem.email.required && errors.push('Email is required.')
				return errors
			},

			streetError() {

				const errors = []
				if (!this.$v.editedItem.street.$dirty) {
					return errors
				}
				!this.$v.editedItem.street.required && errors.push('Street is required.')
				return errors
			},

			suiteError() {

				const errors = []
				if (!this.$v.editedItem.suite.$dirty) {
					return errors
				}
				!this.$v.editedItem.suite.required && errors.push('Suite is required.')
				return errors
			},

			cityError() {

				const errors = []
				if (!this.$v.editedItem.city.$dirty) {
					return errors
				}
				!this.$v.editedItem.city.required && errors.push('City is required.')
				return errors
			},

			stateError() {

				const errors = []
				if (!this.$v.editedItem.state.$dirty) {
					return errors
				}
				!this.$v.editedItem.state.required && errors.push('State is required.')
				return errors
			},

			zipError() {

				const errors = []
				if (!this.$v.editedItem.zip.$dirty) {
					return errors
				}
				!this.$v.editedItem.zip.required && errors.push('Zip code is required.')
				!this.$v.editedItem.zip.minValue && errors.push('Enter correct zip')
				return errors
			},

			serviceRadiusError() {

				const errors = []
				if (!this.$v.editedItem.service_radius.$dirty) {
					return errors
				}
				!this.$v.editedItem.service_radius.required && errors.push('Radius is required.')
				return errors
			},
		},

		created() {
		}
	}
</script>

<style lang="scss" scoped>

	.v-label {
		font-size: 14px !important;
	}

	.stripe-card {
		width: 100%;
		height: 45px;
		border-radius: 4px;
	}
</style>