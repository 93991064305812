<template>
	<OrganizationTable/>
</template>

<script>

	import OrganizationTable from "../components/Organization/OrganizationTable";

	export default {
		name: 'Organization',

		components: {
			OrganizationTable
		},

	}
</script>
