<template>
	<div>

		<v-row class="d-flex justify-center align-center">
			<v-col
				cols="12"
				sm="12"
				md="10"
			>
				<h1 class="my-3"> Equipment</h1>
			</v-col>

			<v-col
				cols="12"
				sm="12"
				md="2"
			>
				<v-btn
					color="primary"
					dark
					class="mb-2 px-8"
					:color="'green'"
					@click.native="openNewEquipmentDialog"
				>
					New
				</v-btn>
			</v-col>

		</v-row>

		<v-row>
			<v-col
				v-for="(equipment, index) in equipments"
				:key="index + '-equipment'"
				:cols="12"
				lg="4"
				md="12"
				sm="12"
				xs="12"
			>
				<v-hover v-slot="{ hover }">
					<v-card
						class="mx-auto"
						max-width="500"
						height="200"
						max-height="300"
						:elevation="hover ? 10 : 2"
						:class="{ 'on-hover': hover }"
						outlined

					>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title class="text-h5 mb-1 font-weight-bold">
									{{ equipment.name }}
								</v-list-item-title>
								<v-spacer></v-spacer>

								<v-list-item-subtitle>

									<p>
										<span> <b>  Current responsible party: </b> </span> <br>
										<span> {{ equipment.responsible }} </span> <br>

										<span> <b> Current location: </b> </span> <br>
										<span> {{ equipment.location }} </span> <br>

									</p>
								</v-list-item-subtitle>
							</v-list-item-content>

							<!--							<v-icon-->
							<!--								size="92"-->
							<!--								class="pt-5 mt-2"-->
							<!--								:color="hover ? 'gray' : 'black'"-->
							<!--							>-->
							<!--								{{ equipment.icon }}-->
							<!--							</v-icon>-->

							<v-img
								class="pt-5 mt-2"
								max-height="120"
								max-width="100"
								src="@/assets/img/tool.webp"
							></v-img>
						</v-list-item>

						<v-divider class="pt-1"></v-divider>

						<v-card-actions class="py-1">

							<div v-if="equipment.tag">
								<v-icon>
									mdi-tag
								</v-icon>

								{{ equipment.tag }}
							</div>
							<v-spacer></v-spacer>

							<div class="text-center">
								<v-menu offset-y>
									<template v-slot:activator="{ on, attrs }">

										<v-btn
											v-bind="attrs"
											v-on="on"
										>
											Actions
											<v-icon
												right
												dark
												large
											>
												mdi-menu-down
											</v-icon>
										</v-btn>

									</template>
									<v-list>
										<v-list-item
											v-for="(item, index) in items"
											:key="index + '-profile'"
											@click.native="openActionModals(index, equipment)"
											link
										>
											<v-list-item-title>{{ item.title }}</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</v-card-actions>
					</v-card>
				</v-hover>
			</v-col>
		</v-row>

		<EquipmentAddDialog
			:key="equipmentKey + '-add'"
			v-model="equipmentDialog"
			@save="save"
		/>

		<EquipmentAddTagDialog
			:key="equipmentTagKey+ '-tag'"
			v-model="equipmentTagDialog"
		/>

		<EquipmentDeleteDialog
			:edited-item-prop="editedItem"
			:key="equipmentDeleteKey + '-delete'"
			v-model="equipmentDeleteDialog"
			@deleteItemConfirm="deleteItemConfirm"
		/>
	</div>
</template>

<script>

	import OrganizationTable from "../components/Organization/OrganizationTable";
	import EquipmentAddDialog from "../components/Equipment/EquipmentAddDialog";
	import EquipmentAddTagDialog from "../components/Equipment/EquipmentAddTagDialog";
	import EquipmentDeleteDialog from "../components/Equipment/EquipmentDeleteDialog";

	export default {
		name: 'Equipment',

		components: {
			EquipmentDeleteDialog,
			EquipmentAddTagDialog,
			EquipmentAddDialog,
			OrganizationTable
		},

		data: () => ({

			equipmentKey: 0,
			equipmentDialog: false,
			equipmentTagKey: 0,
			equipmentTagDialog: false,
			equipmentDeleteKey: 0,
			equipmentDeleteDialog: false,
			equipments: [
				{
					name: "Dewalt Drill",
					location: "123 South St. HappyTown, GA, 12345",
					responsible: "Johny Contractor",
					email: "johny@example.com",
					phone: "770-123-4567",
					icon: "mdi-tools",
					tag: "1543-4756"
				},
				{
					name: "Dewalt Drill",
					location: "123 South St. HappyTown, GA, 12345",
					responsible: "Johny Contractor",
					email: "johny@example.com",
					phone: "770-123-4567",
					icon: "mdi-tools"
				},
				{
					name: "Dewalt Drill",
					location: "123 South St. HappyTown, GA, 12345",
					responsible: "Johny Contractor",
					email: "johny@example.com",
					phone: "770-123-4567",
					icon: "mdi-tools"
				},
				{
					name: "Dewalt Drill",
					location: "123 South St. HappyTown, GA, 12345",
					responsible: "Johny Contractor",
					email: "johny@example.com",
					phone: "770-123-4567",
					icon: "mdi-tools"
				},
				{
					name: "Dewalt Drill",
					location: "123 South St. HappyTown, GA, 12345",
					responsible: "Johny Contractor",
					email: "johny@example.com",
					phone: "770-123-4567",
					icon: "mdi-tools"
				},
				{
					name: "Dewalt Drill",
					location: "123 South St. HappyTown, GA, 12345",
					responsible: "Johny Contractor",
					email: "johny@example.com",
					phone: "770-123-4567",
					icon: "mdi-tools"
				},
			],
			items: [
				{title: 'Tag'},
				{title: 'Delete'},
			],

			editedIndex: -1,
			editedItem: {
				name: '',
			},
		}),

		methods: {

			openNewEquipmentDialog() {

				this.equipmentKey++;
				this.equipmentDialog = true;
			},

			openAddTagDialog() {

				this.equipmentTagKey++;
				this.equipmentTagDialog = true;
			},

			openDeleteItemDialog(item) {

				this.editedIndex = this.equipments.indexOf(item);
				this.editedItem = Object.assign({}, item);
				this.equipmentDeleteKey++;
				this.equipmentDeleteDialog = true;
			},

			openActionModals(index, item) {

				if (index == 0) {

					this.openAddTagDialog();
					return
				}

				this.openDeleteItemDialog(item);
			},

			save(item) {

				this.equipments.push(item);
				this.$store.dispatch('snackbar/showSnackbarMessage', {
					message: 'Equipment was added successfully',
					duration: 4000,
					mode: 'success'
				});
			},

			closeDelete() {

				this.editedIndex = -1;
				this.editedItem = {
					name: ''
				};
				this.equipmentDeleteDialog = false;
			},

			deleteItemConfirm() {

				this.equipments.splice(this.editedIndex, 1);
				this.closeDelete();
			},
		}
	}
</script>
