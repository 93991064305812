import $axios from '../plugins/axios'

class OrganizationsService {

	getOrganizations() {

		return $axios.get('/organizations')
			.then((res) => {

				return res.data;
			});
	}

	createOrganization(bodyFormData) {

		return $axios.post('/organizations', bodyFormData)
			.then((res) => {

				return res.data;
			});
	}

	editOrganization(bodyFormData, id) {

		return $axios.put(`/organizations/${id}`, bodyFormData)
			.then((res) => {

				return res.data;
			});
	}

	deleteOrganization(id) {

		return $axios.delete(`/organizations/${id}`)
			.then((res) => {

				return res.data;
			});
	}
}

export default new OrganizationsService();